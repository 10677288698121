import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import './index.scss';

import Button from "../../../common/Button";
import ButtonInput from "../../../common/ButtonInput";
import MSGDialogue from '../../../components/Dialogue';
import axios from 'axios'
import {APIurl,GlobalUser,rootUrl} from '../../../App';



export default function Register(){

  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);



     
    
    const  process = (event)=>{
        event.preventDefault();
      let fnames = document.getElementById("fnames").value;
      let sname = document.getElementById("sname").value;
      let category = document.getElementById("category").value;
      let vnumber = document.getElementById("vnumber").value;
      let odl = document.getElementById("odl").value;
      var ok  =1;
    
      let cmd = document.getElementById("cmd").value;
    
      if(ok==1){
         
          axios.post(APIurl,{ 
                    cmd: cmd,
                    category:category,
                    itemname:fnames,
                    itemd:sname,
                    date:vnumber,
                    cost:odl,
                    username:GlobalUser
        } )
            .then((response) => {
            
              console.log(response.data);
              document.getElementById("register_form").reset();
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                setLogingmsg(res["data"]);
                  setColors(" text-white bg-success");
                  setTimeout(()=>{
                   
                    // eslint-disable-next-line no-restricted-globals
                  location.href = rootUrl + "/Expenditure";
                 // location.ref = ;
                },1500);
                
                
                  
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
              // eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
                // Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Add New Slip </h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='register-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' id='register_form' action='process/login' onSubmit={process} >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='addslip' hidden/>
                        <h4 className="theme-color ">Fill in Slip form below</h4>
                        <hr />
                       
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Item Name(s):</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' name="fnames" id="fnames" type="text" required/>             
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Category:</b>
                            </div>
                            <div className='col-md-6'>
                              <select name="category" id="category" className='form-control text-center' required>
                                <option value=''>-------Choose category------</option>
                                <option value='Visitor'>Visitor</option>
                                <option value='Cleaning'>Cleaning</option>
                                <option value='Salary'>Salary</option>
                                <option value='Meetings'>Meetings</option>
                                <option value='Other'>Other</option>
                               
                              </select>
                            
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Item Description:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control'  name="sname" id="sname" type="text" required></textarea>
                            </div>
                            
                        </div>
                       
                       
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Date:</b>
                            </div>
                            <div className='col-md-6'>
                            <input  defaultValue="10/11/2022" type="date" className='form-control' name="vnumber" id="vnumber" required/>
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Total Cost:</b>
                              
                            </div>

                            <div class="col-md-6">
                              <div className="input-group">
                              <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1">
                                <select name="currency" type="text" style={{border:"0 solid #ccc",
                                backgroundColor:"#e9ecef"}}>
                                  <option>R</option>
                                  <option>$</option>
                                  <option>€</option>
                                  <option>₹</option>
                                  <option>R$</option>
                                  <option>¥</option>
                                  <option>BZ$</option> 
                                  <option>L</option> 
                                  <option>Ft</option> 
                                  <option>kr</option> 
                                  <option>BZ$</option> 
                                 
                                </select>
                              </span>
                            </div>
                            <input type="text" class="form-control" placeholder="0.00" name="odl" id="odl" aria-label="odl" aria-describedby="basic-addon1"/>

                              </div>
                            
                          </div>
                            
                        </div>
                      
                       
                       <div className='form-group my-2 mx-auto w-md-50'>
                       <ButtonInput value = "Add Slip" active="btn-deactive my-3" />
                       </div>
                        
                    </form>
    
                </div>
       </Container>

        </>
    );
}


import React from 'react';
import { Link } from "react-router-dom";
import './index.css';
import { RotatingLines,InfinitySpin } from  'react-loader-spinner'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";



export default function Loader(props){
    return (
        <>
       <div className="container-fluid text-center"><p className='text-dark loadingText'>Loading...</p>
       
   
        <RotatingLines
  strokeColor="grey"
  strokeWidth="5"
  animationDuration="0.75"
  width="45"
  visible={true}
/> 
       </div>
        </>
    );


}
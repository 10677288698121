import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap';
import './index.scss';
import { Link } from "react-router-dom";
import Button  from "../../common/Button";
import axios from 'axios'
import {APIurl, GlobalUser} from '../../App';
import TableUser from '../../components/TableUser'
import MSGDialogue from '../../components/Dialogue';
import { PieChart } from 'react-minimal-pie-chart';

export default function Expendature(){

  const [members,setMember] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [totalcost, setTotalCost] = useState(0);
  const [totalcleaning, setCleaning] = useState(0);
  const [totalvisitor, setVisitor] = useState(0);
  const [totalmeetings, setMeetings] = useState(0);
  const [totalsalary, setSalary] = useState(0);
  const [totalother, setOther] = useState(0);
  const [logging, setLogging] = useState(false);
  
  const [diaglogueID, setdiaglogueID] = useState("msg13");
  
useEffect(() => {
    
//Get all members registered in the system
  axios.post(APIurl,{ 
      cmd: "getallexpense",
      username:GlobalUser
      
      } )
      .then((response) => {

      var res = response.data;

      if(!res["success"]){   
          setLogingmsg(res["data"]);
          setColors("Could not send request");
      }
      else{

          console.log(res["data"]);

         // setMember(costs);
          setMember(res["data"]);
          var total = 0;
          var cleaning = 0;
          var Salary = 0;
          var Meetings = 0;
          var visitors = 0;
          var other = 0;
          for (let i=0; i<res["data"].length; i++) {

            total += parseFloat(res["data"][i].cost);
            if(res["data"][i].category == "Cleaning"){
              cleaning += parseFloat(res["data"][i].cost);
            }
            else if( res["data"][i].category == "Salary"){
              Salary += parseFloat(res["data"][i].cost);
            }
            else if( res["data"][i].category == "Meetings"){
              Meetings += parseFloat(res["data"][i].cost);
            }
            else if( res["data"][i].category == "Visitor"){
              visitors += parseFloat(res["data"][i].cost);
            }
            else{
              other += parseFloat(res["data"][i].cost);
            }
          }
         
          setTotalCost(total); 
          cleaning = (cleaning/total)*100
          setCleaning(cleaning.toFixed(2));
          visitors = (visitors/total)*100
          setVisitor(visitors.toFixed(2));
          Meetings = (Meetings/total)*100
          setMeetings(Meetings.toFixed(2));
          Salary = (Salary/total)*100
          setSalary(Salary.toFixed(2));
          other = (other/total)*100
          setOther(other.toFixed(2));
         
  
          
          setLogging(true);
          document.getElementById(diaglogueID).style.display = "block"; 
         
      }
     
      })
      .catch((error) => {

      console.log(error);
      
      });


}, []);

const defaultLabelStyle = {
  fontSize: '5px',
  fill: '#fff',
  backgroundColor: '#fff',
  fontFamily: 'sans-serif',
};


      var table_headers = [
        {id:1,name:"ID"},{id:2,name:"Item"},{id:3,name:"Description"},{id:4,name:"Date"},
      {id:5,name:"Total cost"},{id:6,name:"Category"},
     {id:8,name:"Manage"}
    ];

    var buttons = [{id:2,title:"Create Slip",path:"createslip",icon:"fa fa-plus mx-2"}];
   
    var PieData = [];
    if(totalcleaning !=0){
      PieData.push( { title: 'Cleaning Expense', value: totalcleaning, color: '#0016E0' });
    }
    if(totalvisitor !=0){
      PieData.push( { title: "Visitor's Expense", value: totalvisitor, color: '#1c0235' });
    }
    if(totalmeetings !=0){
      PieData.push({title: 'Meetings Expense', value: totalmeetings, color: '#FF0000' });
    }
    if(totalsalary !=0){
      PieData.push({ title: 'Salaries Expense', value: totalsalary, color: '#962929' });
    }
    if(totalother !=0){
      PieData.push({title: 'Other Expense', value: totalother, color: '#000'});
    }

    return (
        <>
         {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Manage Expenditure</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>

       </Container>
       <div className='bg-white mx-3 rounded p-1'>
        <div className='container-fluid m-0 '>
          <div className='d-flex flex-wrap mt-3 mb-0 animate__animated animate__fadeInLeft'>
            <div className='form-group mx-2 d-flex flex-row mb-0 mt-1'> <b className='pt-2'>From</b> <input  defaultValue="2022-12-08" type="date" className='form-control mx-2' name="From"/></div>
            <div className='form-group mx-2 d-flex flex-row mb-0 mt-1'> <b className='pt-2'>To</b><input type="date" className='form-control mx-2' defaultValue="2023-05-25" name="From"/></div>
          </div>
          <hr />
          <div className='row m-0 p-0n bg-white'>
            <div className='col-sm-6 p-1'>
     
            <PieChart className='pie animate__animated animate__fadeIn'
              data={PieData}
              label={({ dataEntry }) => dataEntry.value + "%"}
              labelPosition = {60}
              lineWidth = {60}
              totalValue = {100}
              paddingAngle = {0}
              labelStyle={{
                ...defaultLabelStyle,
              }}
            />
            </div>
            <div className='col-sm-6 p-1 animate__animated animate__fadeIn'>
              <div className='card p-2'>
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#0016E0'}}></span>
                  <span className='mx-3 bold' >Cleaning Expense</span>
                </div>
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#1c0235'}}></span>
                  <span className='mx-3 bold' >Visitor's Expense</span>
                </div>
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#FF0000'}}></span>
                  <span className='mx-3 bold' >Meetings Expense</span>
                </div>
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#962929'}}></span>
                  <span className='mx-3 bold' >Salaries Expense</span>
                </div> 
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#000'}}></span>
                  <span className='mx-3 bold' >Other Expense</span>
                </div>
              </div>

              <div className='form-group my-2 animate__animated animate__fadeInUp'>
              <span className='h4'>Total: R{totalcost.toFixed(2)}</span>
            </div>

            </div>
            
          </div>
          <hr />
     

        </div>
       <TableUser len={members.length}  name="Expenditure" edit="/editexpenses" delete="/expensedelete" table_headers = {table_headers} members={members} buttons={buttons}/>

       </div>
        </>
    );
}


import React from 'react';
import { Link } from "react-router-dom";
import './index.scss';




export default function Button(props){
    return (
        <>
        <Link to={props.path} className="mr-1"  style={{textDecoration:'none'}}>
            <div onClick={() => props.action(props.id,props.path)} id={props.id} className={ props.active + ' theme-button button p-2 rounded text-center mr-1'  }>
                <span className="position-relative left-0 ml-1 mr-2 "><i className={props.icon}></i></span>
                <span className='text-center'  style={{whiteSpace: "nowrap"}} ><b>{props.title}</b> </span>
            </div>
        </Link>
      
        </>
    );


}
import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import './index.scss';

import Button from "../../../common/Button";
import ButtonInput from "../../../common/ButtonInput";
import MSGDialogue from '../../../components/Dialogue';
import axios from 'axios'
import {APIurl,GlobalUser,rootUrl} from '../../../App';



export default function AddParcel(){

  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);
    
    const  process = (event)=>{
        event.preventDefault();
      let pname = document.getElementById("pname").value;
      let oname = document.getElementById("oname").value;
      let cellphone = document.getElementById("cellphone").value;
      let email = document.getElementById("email").value;
      let description = document.getElementById("description").value;
      let pickup = document.getElementById("pickup").value;
      let Delivery = document.getElementById("Delivery").value;
      let pickupdate = document.getElementById("pickupdate").value;
      let deliverydate = document.getElementById("deliverydate").value;
      var ok  =1;
    
      let cmd = document.getElementById("cmd").value;
    
      if(ok==1){
         
          axios.post(APIurl,{ 
                    cmd: cmd,
                    pname:pname,
                    oname:oname,
                    cellphone:cellphone,
                    email:email,
                    pickup:pickup,
                    Delivery:Delivery,
                    pickupdate:pickupdate,
                    deliverydate:deliverydate,
                    description:description,
                    username:GlobalUser
        } )
            .then((response) => {
            
              console.log(response.data);
              document.getElementById("register_form").reset();
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                    setLogingmsg(res["data"]);
                    setColors(" text-white bg-success");
                    setTimeout(()=>{
                    // eslint-disable-next-line no-restricted-globals
                  location.href = rootUrl + "/parcels";
                 // location.ref = ;
                },1500);
                  
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
              // eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
                // Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Record Expense </h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='register-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' id='register_form' action='process/login' onSubmit={process} >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='addparcel' hidden/>
                        <h4 className="theme-color ">Fill in form below</h4>
                        <hr />
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel Name:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="pname" id="pname" type="text" required/>
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel owner's Name:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="oname" id="oname" type="text" required/>
                            </div>
                        </div>

                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel owner's cellphone number:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="cellphone" id="cellphone" type="text" required/>
                            </div>
                        </div>
                        
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel owner's Email address:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="email" id="email" type="email" required/>
                            </div>
                        </div>

                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel Description:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control'  name="description" id="description" type="text" required></textarea>
                            </div>
                            
                        </div>

                        
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Pick Address:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control' rows={6} placeholder="Physical Address where the driver pick up the parcel" name="pickup" id="pickup" type="text" required></textarea>
                            </div>
                            
                        </div>

                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Delivery Address:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control'  rows={6} name="Delivery" id="Delivery" placeholder="Physical Address where the driver will drop up the parcel to the owner" type="text" required></textarea>
                            </div>
                            
                        </div>

                       
                       
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Pickup Date:</b>
                            </div>
                            <div className='col-md-6'>
                            <input  defaultValue="10/11/2022" type="date" className='form-control' name="pickupdate" id="pickupdate" required/>
                            </div>
                            
                        </div>
                        
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Delivery Date:</b>
                            </div>
                            <div className='col-md-6'>
                            <input  defaultValue="10/11/2022" type="date" className='form-control' name="deliverydate" id="deliverydate" required/>
                            </div>
                            
                        </div>
                        
                      
                       
                       <div className='form-group my-2 mx-auto w-md-50'>
                       <ButtonInput value = "Add Parcel" active="btn-deactive my-3" />
                       </div>
                        
                    </form>
    
                </div>
       </Container>

        </>
    );
}


import React from 'react';

import './index.scss';
import danger from "../../assets/img/danger-sign.png";




export default function Nopage(props){

    return(
        <>
        <div className="mt-3 ml-4">
            <hr />
            <div className='p-2'><div className='container'><img className='danger-sign m-auto'src={danger}  alt="danger"/></div><br/><span className="h3 text-danger">Opps!!! We couldn't find the page you are looking for</span></div>
        </div>
        </>
    )
    ;

}
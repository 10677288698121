import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap';
import './index.scss';
import { Link } from "react-router-dom";
import Button  from "../../../common/Button";
import axios from 'axios'
import {APIurl,getCookie, GlobalDays, GlobalType, GlobalUser} from '../../../App';
import TableUser from '../../../components/TableUser/parcels.js'
import MSGDialogue from '../../../components/Dialogue';
import { PieChart } from 'react-minimal-pie-chart';



export default function Parcels(){
  
let GlobalUser1 = getCookie("softransuser");


  const [members,setMember] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [totalMembers, setTotalMembers] = useState("0");
  const [users, setUsers] = useState(0);
  const [LTOA, setLTOA] = useState("0");
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg548");
  

  useEffect(() => {
    
    
    //Get all members registered in the system
      axios.post(APIurl,{ 
          cmd: "getallparcels",
         type:"uncollected",
          username:GlobalUser
          
          } )
          .then((response) => {
    
          var res = response.data;
         
    
          if(!res["success"]){   
            console.log(response.data);
              setLogingmsg("Error Encountered: ");
              setColors("Could not send request");
          }
          else{
    
              console.log(res["data"]);
              setMember(res["data"].reverse());
              setLogging(true);
              try {
                document.getElementById(diaglogueID).style.display = "block"; 
              } catch (error) {
                
              }
              
             
          }
         
          })
          .catch((error) => {
    
          console.log(error);
          
          });
    
    
    }, []);
    





  var table_headers = [
                        {id:1,name:"ID"},{id:3,name:"Parcel Name"},{id:4,name:"Owner's Name"},
                        {id:5,name:"Cellphone"},{id:6,name:"Email"},
                        {id:8,name:"Pickup Address"},{id:9,name:"Delivery Address"},{id:10,name:"Pickup Date"},
                        {id:11,name:"Delivery Date"},  {id:12,name:"Description"}, {id:13,name:"Manage"}
                      ];

  var buttons = [{id:2,title:"Add Parcel",path:"/driver/addparcel",icon:"fa fa-plus mx-2"}];
  
   

    return (
       
       <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 
        <div className='mr-0 ml-0 p-2 mt-2 mb-0 container-fluid'><h3>Manage Parcels</h3>
            <div className='row m-0 p-0 bg-white'>
                <div className='col-sm-6 p-1'>
                </div>
            </div>
            <hr />
        </div>
        <br/>
        <TableUser len={members.length}  name="Current Tracking Parcels" edit="/driver/editparcel" delete="/driver/deleteparcel" table_headers = {table_headers} members={members} buttons={buttons}/>
        </>

    );
}


import React, { useEffect, useState } from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './containers/Home';
import Layout from './containers/Layout';
import './App.css';
import './assets/style/index.scss';
import "animate.css";

//  export const APIurl = "http://localhost:81/softrans/API.php"; 
//  export const BaseAPIurl = "http://localhost:81/softrans/"; 

export const GlobalUser = getCookie("softransuser");
export const GlobalType = getCookie("softranstype");
export const GlobalStatus = getCookie("status");
export var isDriver= false;

if(GlobalType === 'driver'){
  isDriver= true;
}

export const GlobalDays = getCookie("softransdays");

export const APIurl = "https://medsmadiagane.co.za/API.php"; 
export const BaseAPIurl = "https://medsmadiagane.co.za/"; 

//eslint-disable-next-line no-restricted-globals 
var BaseURL = location.href;
                BaseURL = BaseURL.split("/");
                BaseURL = BaseURL[0] + BaseURL[1] + "//"+ BaseURL[2];
export const rootUrl = BaseURL;

export function callcookie(name,value,days){

  if(days){
      var d =  new Date();
      d.setTime(d.getTime()+(days*24*60*60*1000));
      var expires = "; expires=" + d.toGMTString();
  }
  else var expires = "";
  document.cookie = name+"="+value+expires+"; ";

}

export function deletecookie(name){

  document.cookie = name+"= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  
 }
 
 
export function getCookie(name) {
  var match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)')); 
  return match ? match[1] : null;
}


function App() {


  return (

    <Layout/>
  
    );
}


export default App;

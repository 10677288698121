import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import './index.scss';

import Button from "../../../common/Button";
import ButtonInput from "../../../common/ButtonInput";
import MSGDialogue from '../../../components/Dialogue';
import axios from 'axios'
import {APIurl,rootUrl,GlobalUser} from '../../../App';



export default function EditMember(){

  const [member, setMember] = useState({});
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);
        
    useEffect(()=>{

    //eslint-disable-next-line no-restricted-globals
    var id = location.href;
    id = id.split("id=");
    id  = id[1];
    //Get member details from by ID in the database
    axios.post(APIurl,{ 
        cmd: "getmeetingbyid",
        id:id
        
        } )
        .then((response) => {

        var res = response.data;

        if(!res["success"]){   
            setLogingmsg(res["data"]);
            setColors("text-white bg-danger");
            console.log("Message Sent 1");
        }
        else{

            console.log(res["data"]);
           
            setMember(res["data"][0]);
            setLogging(true);
            document.getElementById(diaglogueID).style.display = "block"; 
        
        }
    
        })
        .catch((error) => {

        console.log(error);
        
        });

    },[]);   


    //Now process the edited information about the member
        
   
    
    const  process = (event)=>{
        event.preventDefault();
        let fnames = document.getElementById("fnames").value;
        let sname = document.getElementById("sname").value;
        let vnumber = document.getElementById("vnumber").value;
        let id = document.getElementById("id").value;
        let odl = document.getElementById("odl").value;
      var ok  =1;
    
      let cmd = document.getElementById("cmd").value;
    
      if(ok==1){
         
          axios.post(APIurl,{ 
            cmd: cmd,
            id:id,
            venue:fnames,
            time:sname,
            date:vnumber,
            type:odl
        } )
            .then((response) => {
            
              console.log(response.data);
              document.getElementById("register_form").reset();
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                setLogingmsg("Information edited successfully");
                  setColors(" text-white bg-success");
                  setTimeout(()=>{
                   
                    // eslint-disable-next-line no-restricted-globals
                  location.href = rootUrl + "/receipts/attendance";
                 // location.ref = ;
                },1500);
                
                
                  
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
              // eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
                // Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }

    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Edit Stand information</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='register-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' id='register_form' action='process/login' onSubmit={process} >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='editmeeting' hidden/>
                    <input className='form-control' name="id" id="id" type="text" defaultValue={member.id} hidden/>
                        <h4 className="theme-color ">Edit  information in the form below</h4>
                        <hr />
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Venue: </b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' defaultValue={member.venue} name="fnames" id="fnames" type="text" required/>             
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Date:</b>
                            </div>
                            <div className='col-md-6'>
                            <input   type="date" className='form-control' defaultValue={member.date1} name="vnumber" id="vnumber" required/>
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Time:</b>
                            </div>
                            <div className='col-md-6'>
                            <input  type="time" className='form-control' defaultValue={member.time1} name="sname" id="sname" required/>
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Meeting Type:</b>
                            </div>
                            <div className='col-md-6'>

                            <select name="nationality" id="odl" className='form-control text-center' disabled>

                                        <option defaultValue={member.type}>{member.type}</option>

                            </select>
                            </div>
                        </div>
                        
                       <div className='form-group my-2 mx-auto w-md-50'>
                       <ButtonInput value = "Save Information" active="btn-deactive my-3" />
                       </div>
                        
                      
                    </form>
    
                </div>
       </Container>

        </>
    );
}


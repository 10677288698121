import React from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import './index.scss';
import { Outlet, Link } from "react-router-dom";
import Header from '../../components/Header';




export default function About(){

    return (
        <>
       <div>This is About </div>
       
        </>
    );
}


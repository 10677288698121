import React from 'react';
import './footer.scss';
import img from '../../assets/img/logo-bg.png';

const Footer = () => {
    return (
        <div id='footer' className='py-3 position-relative'>
          
            <div className='container position-relative'>
                <div className="h4 color-theme text-center">Social links <span className="underline"></span></div> 
                <ul className='list-unstyled d-flex justify-content-center'>
                    <li><a href="https://www.facebook.com/profile.php?id=100048677534165&sk=about"><i className='fa fa-facebook-f fa-fw'></i></a></li>
                    <li><a href="https://www.linkedin.com/company/74040464/admin/"><i className='fa fa-linkedin fa-fw'></i></a></li>
                    <li><a href="https://wa.me/+27788126254"><i className='fa fa-whatsapp fa-fw'></i></a></li>
                    <li><a href="tel:+27788126254"><i className='fa fa-phone fa-fw'></i></a></li>
                </ul>
                <div className="h4 color-theme text-center">Support Team <span className="underline"></span></div> 
                <ul className='list-unstyled d-flex justify-content-center footer-navbar'>
                    <li className='text-white'><i className='fa fa-whatsapp '></i> +27 (0) 788 126 254</li>   
                    <li className='text-white'><i className='fa fa-whatsapp '></i> +27 (0) 728 772 887 </li>
                   
                </ul>
                <div className='d-flex justify-content-center subscribe mt-4'>
                    <div>
                        <i className='fa fa-envelope'></i>
                    </div>
                    <div>
                        <input type="text" placeholder='Enter your email here...' name='Subscribeemail' />
                    </div>
                    <div>
                        <a href="Subscribe">Subscribe <span className='p-hide'>to NewsLetter</span></a>
                    </div>
                </div>
                <p className='copyright text-center mt-3'>Copyright © 2022 All rights reserved - Beamstars computers</p>
                <hr className="bg-white border border-white" />
                <p className='copyright text-center mt-3'>Developed by <a href="#">Beamstar Team</a> </p>
            </div>
            <div className='position-absolute  footer-end '>
                <img src={img} alt="" className='bg-white'/>
            </div>
        </div>
    )
}

export default Footer
import React from 'react';
import {Container,Row,Col,Button,Form} from 'react-bootstrap';
import './index.scss';
import  { useEffect, useState } from 'react';

import logo from '../../assets/img/logo-bg.png';

var track = 0;

export default function Header(){

    var menu = ()=>{

      let  el = document.getElementsByClassName("expand-side");
      let el2 =document.getElementsByClassName("side-main");
      // [0].classList.remove("");
      // document.getElementsByClassName("side-panel")[0].classList.add("collapse-side");
      // document.getElementsByClassName("side-main")[0].classList.add("collapse-side-main");

      if(track==0){
        document.getElementsByClassName("side-panel")[0].classList.remove("p-hide");
        track++;
      }
      else{

        if(el.length>0){
            let buttons = document.getElementsByClassName("menu-btn-text");
            let icons = document.getElementsByClassName("menu-icons");
            for(let i=0; i<buttons.length;i++){
               
                buttons[i].style.display="none";
                icons[i].classList.remove("float-left");
            } 

            document.getElementsByClassName("side-panel")[0].classList.remove("expand-side");
            el2[0].classList.remove("expand-side-main");
            el2[0].classList.add("collapse-side-main");
           
            document.getElementsByClassName("side-panel")[0].classList.add("collapse-side");
          }
          else{
            let buttons = document.getElementsByClassName("menu-btn-text");
            let icons = document.getElementsByClassName("menu-icons");
            for(let i=0; i<buttons.length;i++){
                buttons[i].style.display="inline";
                icons[i].classList.add("float-left");
            } 
            
            document.getElementsByClassName("side-panel")[0].classList.add("expand-side");

            document.getElementsByClassName("side-panel")[0].classList.remove("collapse-side");
            el2[0].classList.add("expand-side-main");
            el2[0].classList.remove("collapse-side-main");
          }
          
      }


    
    }
  

    return (

        <>
        <Container fluid className='mt-0  mb-0 mr-0 ml-0 px-0  top-bar '>

          <Row className='m-0 header-box py-2 w-100'>
              <div className='col-2'><div className='menu-bars hover' onClick={menu}><i className='fa fa-bars'></i></div></div>
              <div  className='col'><div className='float-left mt-1'><h2>SOFTRANS</h2> 
              <div className='col text-danger support' > 
              <a href='mailto:support@beamstarcomputers.co.za' className='mx-2 text-danger'><i className='fa fa-envelope fa-fw mx-2'></i><b><span className='p-hide'>support@beamstarcomputers.co.za</span></b></a>
              <a href='https://wa.me/+27788126254' className='mx-2 text-danger' ><i className='fa fa-whatsapp fa-fw'></i> <b><span className='p-hide'>+27 (0) 788 126 254</span></b></a>
              </div>
               </div> <img className="float-left logo-header" src={logo} /></div>
              {/* <div className='col-sm-5'><div className='text-secondary p-1 '><h4 className='taxi-rank'>Transport Management System</h4></div></div> */}
          </Row>
        </Container>
        </>

    );
}
import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap';
import './index.scss';
import { Link } from "react-router-dom";
import Button  from "../../common/Button";
import axios from 'axios'
import {APIurl,getCookie, GlobalDays, GlobalStatus, GlobalType, GlobalUser} from '../../App';
import TableUser from '../../components/TableUser'
import MSGDialogue from '../../components/Dialogue';


function DashCards(props){
    return (
        <>
        <div className="stats-card my-2 bg-white box-hover transition-all-1s animate__animated animate__fadeInDown">
            <div className="stats-title mb-4">{props.title}</div>
           
          <div className="stats-content d-flex  flex-row">
            <div className='theme-color font-weight-bold m-1 stats-icon'>
           <i className="fa fa-line-chart"></i>
            </div>
            <div className='stat-nums m-1'><b>{props.number}</b></div>
            <div className='stats-bar m-1'></div>

          </div>
        </div>
        </>
    );
}

export default function Home(){
  
let GlobalUser1 = getCookie("softransuser");


  const [members,setMember] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [totalMembers, setTotalMembers] = useState("0");
  const [freelancer, setFreelancer] = useState(0);
  const [users, setUsers] = useState(0);
  const [LTOA, setLTOA] = useState("0");
  const [Days, setDays] = useState(0);
  const [showDays, setShowDays] = useState(false);
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg548");
  
    
useEffect(() => {

  
if(GlobalType=="Rank" || GlobalType=="Individual"){

  let time1 = new Date();
  let secs = time1.getTime();
  secs = GlobalDays - secs;
  secs = secs/1000;
  secs = secs/60;
  secs = secs/60;
  secs = secs/24;
  setDays( Math.round(secs));
  
  if(GlobalStatus == "Trial"){
    setShowDays(true);
  }

}

  //Get all freelancers registered in the system
  axios.post(APIurl,{ 
    cmd: "getallfreelancers",
    username:GlobalUser
    
    } )
    .then((response) => {

    var res = response.data;

    console.log(res);
 
    
    if(!res["success"]){   
        setLogingmsg(res["data"]);
        setColors("bg-danger text-white");
    }
    else{
     
      setFreelancer(res["data"].length);
    
       
    }
   
    })
    .catch((error) => {
    console.log(error);
    });

    axios.post(APIurl,{ 
      cmd: "getallusers",
      type:"owner",
      owner:GlobalUser

      
      } )
      .then((response) => {
  
      var res = response.data;
  
      console.log(res);
   
      
      if(!res["success"]){   
          setLogingmsg(res["data"]);
          setColors("bg-danger text-white");
      }
      else{
       
        setUsers(res["data"].length);
      
         
      }
     
      })
      .catch((error) => {
      console.log(error);
      });
    //Get Members of LTOA


    axios.post(APIurl,{ 
      cmd: "getmembers",
      username:GlobalUser
      
      } )
      .then((response) => {
  
      var res = response.data;
  
      console.log(res);
   
      
      if(!res["success"]){   

          setLogingmsg(res["data"]);
          setColors("bg-danger text-white");

      }
      else{
       
        setLTOA(res["data"].length);
      
         
      }
     
      })
      .catch((error) => {
      console.log(error);
      });
    
//Get all members registered in the system

  axios.post(APIurl,{ 
      cmd: "getallmembers",
      username:GlobalUser
      } )
      .then((response) => {
      var res = response.data;

      if(!res["success"]){   
          setLogingmsg(res["data"]);
          setColors("Could not send request");
      }
      else{

          
          console.log(res["data"]);
          setMember(res["data"]);
          setTotalMembers(res["data"].length);         
          setLogging(true);
         
      }
     
      })
      .catch((error) => {

      console.log(error);
      
      });


}, []);



const Cards = [
  {id: 1, title: 'Total Members',number:LTOA},
  {id: 2, title: 'Total Freelancers',number:freelancer},
  {id: 3, title: 'Total Registered Cars',number:totalMembers}
 
];

if(GlobalType=="Rank" || GlobalType=="owner"){
  Cards.push( {id: 4, title: 'Total Users',number:users}) ;

}



var table_headers = [
        {id:1,name:"ID"},{id:2,name:"Type"},{id:3,name:"Name"},{id:4,name:"Surname"},
      {id:5,name:"Vehicle Reg No"},
      {id:6,name:"Owner's ID No."}, {id:7,name:"Owner's Cellphone"},{id:9,name:"Branch"},{id:8,name:"Manage"}
    ];
    var buttons = [{id:2,title:"Add Member",path:"register",icon:"fa fa-plus mx-2"}]

    return (
       
       <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 
        <div className='mr-0 ml-0 p-2 mt-2 mb-0 container-fluid'><h3>Dashboard</h3>
        {showDays? <span className='float-right text-white px-3 py-1 rounded bg-danger'>
        <i className='fa fa-bell mx-2'></i>
        <b>Your Trial will end in</b> <b className='text-primary'>{Days} days</b> </span>: "" }
        
        </div>
        <Container fluid className='d-flex  my-4 w-100 home-cards'>
        {Cards.map((card) => <DashCards key={card.id} title={card.title} number ={card.number}  />)}
        </Container>
        <br/>
        <TableUser len={members.length}  name="Members" edit="/editmember" delete="/memberdelete" table_headers = {table_headers} members={members} buttons={buttons}/>
        </>

    );
}


import React,{useState,useEffect} from 'react';
import {Container} from 'react-bootstrap';
import './index.scss';
import Button from "../../../common/Button";
import MSGDialogue from '../../../components/Dialogue';
import axios from 'axios'
import {APIurl,rootUrl} from '../../../App';



export default function Edituser(){

  const [username, setUsername] = useState("");
  const [pasword, setPassword] = useState("");
  const [Role, setRole] = useState("");
  const [Address, setAddress] = useState("");
  const [branch, setBranch] = useState("");

  const [err_username, err_setUsername] = useState("");
  const [err_pasword, err_setPassword] = useState("");

  const [loginmsg, setLogingmsg] = useState("");
  const [userID, setUserID] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);
  const [member,setMember] = useState({});


    
useEffect(() => {
    //eslint-disable-next-line no-restricted-globals
var id = location.href;
id = id.split("id=");
id  = id[1];
    axios.post(APIurl,{ 
        cmd: "getuserbyid",
        id: id
        
        } )
        .then((response) => {

        var res = response.data;
        if(!res["success"]){
            setLogingmsg(res["data"]);
            setColors("text-white bg-danger");
            if(res["success"]==null ){
                setLogingmsg("Some error encountered");
            }
           
        }
        else{
          
            setUsername(res["data"][0].username);
            setPassword(res["data"][0].pasword);
            setRole(res["data"][0].Role);
            setBranch(res["data"][0].branch);
            setUserID(res["data"][0].id);
            setAddress(res["data"][0].address);
       
 
        }
            setLogging(true);

       
        })
        .catch((error) => {
        console.log(error);
        })

  }, []);
      
     
    var process = ()=>{
      let username = document.getElementById("username").value;
      let branch = document.getElementById("branch").value;
     
      var ok  =1;
      if(username.length==0){
      var ok  =0;
          
      err_setUsername("Username Cannot be empty");
      }
      else{
        err_setUsername("");
      }
      let pasword = document.getElementById("pasword").value;

      if(pasword.length==0){
      var ok  =0;

      err_setPassword("Password Cannot be empty");
      }
      else{
        err_setPassword("");
      }
      let cmd = document.getElementById("cmd").value;
      let role = document.getElementById("role").value;
      let address = document.getElementById("address").value;
      
    
    
      if(ok==1){
         
         

          axios.post(APIurl,{ 
           
                    username: username,
                    pasword:pasword,
                    cmd: cmd,
                    role:role,
                    branch:branch,
                    address:address,
                    id:userID
              
        } )
            .then((response) => {
            
              console.log(response.data);
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-success");
                  setTimeout(()=>{
                   
                      // eslint-disable-next-line no-restricted-globals
                    location.href = rootUrl + "/adduser";
                   // location.ref = ;
                  },1000);
                  
              
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
              // eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
                // Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Edit User Information</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='login-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' action='process/login' >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='edituser' hidden/>
                   
                        <h4 className="theme-color  text-center">Edit Admin Info</h4>
                        <hr />
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control' placeholder='Enter Username' defaultValue={username}  name="username" id="username" type="text" required/>
                            <span className='text-danger'>{err_username}</span>
                        </div>
                        <div className='form-group my-2 mx-1'>
                            <label>Role</label>
                            <input className='form-control' placeholder='Enter Role' defaultValue={Role}  name="role" id="role" type="text" required/>
                           
                        </div>
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control' placeholder='Enter Password'  name="pasword" id="pasword" type="password" required/>
                            <span className='text-danger'>{err_pasword}</span>
                        </div>
                        <div className='form-group my-2 mx-1'>
                        <label for="address" className="form-label">Rank Address</label>
                            <input className='form-control' defaultValue={Address} placeholder='Enter Physical address of your rank'  name="address"  id="address" type="text" required/>
                        </div>
                        <div className='form-group my-2 mx-1'>
                            <label for="branch" className="form-label">User Branch Name</label>
                            <input className='form-control' defaultValue={branch} placeholder='E.g: MTN Taxi Rank' name="branch" id="branch" type="text" autoComplete="new-password" required/>
                            
                        </div>
                        <Button icon="fa fa-user mx-2" title = "Save information" active="btn-deactive my-3" action={process} />
                      
                    </form>
    
                </div>
       </Container>

        </>
    );
}


import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import './index.scss';

import Button from "../../../common/Button";
import ButtonInput from "../../../common/ButtonInput";
import MSGDialogue from '../../../components/Dialogue';
import axios from 'axios'
import {APIurl,GlobalUser,rootUrl} from '../../../App';
import taxi from '../../../assets/img/Taxi.jpeg';



export default function Register(){

  const [err_ocellphone, setOcellphone] = useState("");
  const [err_dcellphone, setdcellphone] = useState("");
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);
    
  const  process = (event)=>{
        event.preventDefault();
      let fnames = document.getElementById("fnames").value;
      let sname = document.getElementById("sname").value;
      let vnumber = document.getElementById("vnumber").value;
      let oid = document.getElementById("oid").value;
      let ocellphone = document.getElementById("ocellphone").value;
      let odl = document.getElementById("odl").value;
      let workfrom = document.getElementById("workfrom").value;
      let workto = document.getElementById("workto").value;
      let Monday = document.getElementById("Monday").value;
      let Tuesday = document.getElementById("Tuesday").value;
      let Wednesday = document.getElementById("Wednesday").value;
      let Thursday = document.getElementById("Thursday").value;
      let Friday = document.getElementById("Friday").value;
      let Saturday = document.getElementById("Saturday").value;
      let Sunday = document.getElementById("Sunday").value;
      let workdays = Monday + ", " + Tuesday + ", " + Wednesday + ", " + Thursday + ", " + Friday + ", " + Saturday + ", " + Sunday;
      var ok  =1;
    
    //Validate owner's cellphone number 
 
      var ok  =1;
      if(ocellphone.length<10){ //NaN

        var ok  =0;

          setOcellphone("Cell Phone number is too short");

      }
      else if(parseInt(ocellphone) == NaN){

        setOcellphone("Please Enter Valid Cellphone number");

      }
      else{
        setOcellphone("");
      }
      //Validate driver's cellphone number 
   
      var ok  =1;

      let cmd = document.getElementById("cmd").value;
    
    
      if(ok==1){
          axios.post(APIurl,{ 
                    cmd: cmd,
                    passengers:fnames,
                    price:sname,
                    pickup:vnumber,
                    destination:oid,
                    registration:ocellphone,
                    name:odl,
                    workfrom:workfrom,
                    workdays:workdays,
                    workto:workto,
                    username:GlobalUser
                 
        } )
            .then((response) => {
            
              console.log(response.data);
              document.getElementById("register_form").reset();
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                setLogingmsg(res["data"]);
                  setColors(" text-white bg-success");
                  setTimeout(()=>{
                   
                    // eslint-disable-next-line no-restricted-globals
                  location.href = rootUrl + "/vehicle";
                 // location.ref = ;
                },1500);
                
                 
                
                  
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
              // eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
                // Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0 bg-white'><h3>Register new Vehicle </h3></div>
      
       
       <Container fluid className='d-flex flex-row mt-0 w-100 home-cards bg-white'>

       <div className='register-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' id='register_form' action='process/login' onSubmit={process} >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='addvehicle' hidden/>
                        <h4 className="theme-color ">Fill in registration form below</h4>
                        <hr />
                      
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Number of Passangers</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' placeholder='Only numbers are allowed here' name="fnames" id="fnames" type="number" required/>             
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Transport Price( from pick-up to destination):</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="sname" id="sname" type="number" required/> 
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Pick up address:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control'  name="vnumber" id="vnumber" type="text" required></textarea>
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Destination Address:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control'  name="oid" id="oid" type="text" required></textarea> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Car registration Number:</b>
                                <span className='text-danger'>{err_ocellphone}</span>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="ocellphone" id="ocellphone" type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Vehicle Name:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="odl" id="odl" type="text" required/> 
                            </div>
                        </div>
                   
                       
                     
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Working Hours:</b>
                            </div>
                            <div className='col-md-6 d-flex flex-row'>
                           From: <input className='form-control mx-1' name="workfrom" id="workfrom" type="time" /> 
                           To: <input className='form-control mx-1' name="workto" id="workto" type="time" /> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Working Days:</b>
                            </div>
                            <div class="col-md-6 d-flex flex-wrap border p-1">
                                <div className='form-check m-1'>
                                <input class="form-check-input" type="checkbox" value="Monday" id="Monday" />
                                 <label class="form-check-label" for="flexCheckDefault">Monday</label>
                                </div>
                           
                                <div className='form-check m-1'> 
                                    <input class="form-check-input" type="checkbox" value="Tuesday" id="Tuesday" />
                                    <label class="form-check-label" for="flexCheckDefault">Tuesday</label>
                                </div>

                                <div className='form-check m-1'> 
                                    <input class="form-check-input" type="checkbox" value="Wednesday" id="Wednesday" />
                                    <label class="form-check-label" for="flexCheckDefault">Wednesday</label>
                                </div>

                                <div className='form-check m-1'> 
                                    <input class="form-check-input" type="checkbox" value="Thursday" id="Thursday" />
                                    <label class="form-check-label" for="flexCheckDefault">Thursday</label>
                                </div>

                                <div className='form-check m-1'> 
                                    <input class="form-check-input" type="checkbox" value="Friday" id="Friday" />
                                    <label class="form-check-label" for="flexCheckDefault">Friday</label>
                                </div>
                                <div className='form-check m-1'> 
                                    <input class="form-check-input" type="checkbox" value="Saturday" id="Saturday" />
                                    <label class="form-check-label" for="flexCheckDefault">Saturday</label>
                                </div>
                                <div className='form-check m-1'> 
                                    <input class="form-check-input" type="checkbox" value="Sunday" id="Sunday" />
                                    <label class="form-check-label" for="flexCheckDefault">Sunday</label>
                                </div>
                        </div>
                            
                        </div>
                        

                       <div className='form-group my-2 mx-auto w-md-50'>
                       <ButtonInput value = "Add Member" active="btn-deactive my-3" />
                       </div>
                        
                      
                    </form>
    
                </div>
       </Container>

        </>
    );
}


import React,{useEffect,useState} from 'react';

import './index.scss';
import { Link } from "react-router-dom";
import Button  from "../../common/Button";
import axios from 'axios'
import {APIurl,rootUrl,getCookie} from '../../App';
import MSGDialogue from '../../components/Dialogue';
import ToggleSwitch from '../../components/ToggleSwitch';

export default function Settings(){

  const [loginmsg, setLogingmsg] = useState("");

  const [logging, setLogging] = useState(false);

  const [colors, setColors] = useState("");

  const [diaglogueID, setdiaglogueID] = useState("msg13");
  
    
useEffect(() => {
  if(getCookie("emails")=="true"){
    document.getElementById("emails").checked = true;
  }
  if(getCookie("admins")=="true"){
    document.getElementById("admins").checked = true;
  }
  
  if(getCookie("members")=="true"){
    document.getElementById("members").checked = true;
  }

}, []);

var buttons = [{id:1,title:"Export CSV",path:"exportmembers"},{id:2,title:"Add receipt",path:"addreceipt"}];

    return (
        <>
         {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : "" } 
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Manage Settings</h3></div>
       <div className='bg-white mx-2 rounded-top box-shadow px-0 pt-0  py-0 position-relative top-0 animate__animated animate__fadeInDown'>
        <div className='container p-3 m-3'>

            <ToggleSwitch label="Receive promotional emails"  id="emails" />
            <ToggleSwitch label="Allow sub-admins to add users"  id="admins"  />
            <ToggleSwitch label="Receive  alert when new member joins" id="members" />

        </div> 
         </div> 
         </>
    );

}


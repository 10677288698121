import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap';
import './index.scss';
import axios from 'axios'
import {APIurl,GlobalUser} from '../../App';
import TableUser from '../../components/TableUser'
import MSGDialogue from '../../components/Dialogue';
import taxi from '../../assets/img/Taxi.jpeg';


export default function Worker(){

  const [members,setMember] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg16");
  
    
useEffect(() => {

  
    
//Get all members registered in the system
  axios.post(APIurl,{ 
      cmd: "getallvehicle",
      username:GlobalUser
      
      } )
      .then((response) => {

      var res = response.data;
      

      if(!res["success"]){   
          setLogingmsg(res["data"]);
          setColors("Could not send request");
      }
      else{

          console.log(res["data"]);
          setMember(res["data"]);

       
          setLogging(true);
          document.getElementById(diaglogueID).style.display = "block"; 
         
      }
     
      })
      .catch((error) => {

      console.log(error);
      
      });


}, []);
    

      var table_headers = [
        
        {id:1,name:"ID"},{id:3,name:"No. of passengers"},{id:4,name:"Price"},
      {id:5,name:"Pick-Up"},
      {id:6,name:"Destination "}, {id:7,name:"Reg No."},{id:2,name:"Vehicle Name"},{id:9,name:"Working Days"},{id:10,name:"Work From"},{id:11,name:"Work To"},{id:8,name:"Manage"}
    ];
    var buttons = [{id:2,title:"Add Vehicle",path:"addvehicle",icon:"fa fa-plus mx-2"},{id:3,title:"View Insurances",path:"/insurance",icon:"fa fa-money mx-2"}]

    return (
        <>

         {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 

       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Manage Vehicles</h3></div>
       <div className='container-fluid m-0 text-center bg-white'>
       <img src={taxi} className='w-50 mx-auto '/>
       </div>

       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'></Container>
     <TableUser len={members.length} name="Transports" edit="/editvehicle" delete="/vehicledelete" table_headers = {table_headers} members={members} buttons={buttons}/>

      
        </>
    );
}


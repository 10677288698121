import React from 'react';
import { Link } from "react-router-dom";
import './index.scss';




export default function ButtonInput(props){
    return (
        <>
        <div className='btn-submit animate__animated animate__bounce animate__delay-1s'>
    
        <input type="submit"  style={{whiteSpace: "nowrap"}} className={ props.active + ' form-control theme-button button p-2 rounded text-center mx-1'  } value={props.value} />
       
    </div>
        </>
    );


}
import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import './index.scss';

import ButtonInput from "../../../common/ButtonInput";
import MSGDialogue from '../../../components/Dialogue';
import axios from 'axios';
import {APIurl,rootUrl,GlobalUser} from '../../../App';



export default function Register(){

  const [loginmsg, setLogingmsg] = useState("");

  const [colors, setColors] = useState("");

  const [diaglogueID, setdiaglogueID] = useState("msg50");

  const [logging, setLogging] = useState(false);
    
    const  process = (event)=>{
        event.preventDefault();
      let fnames = document.getElementById("fnames").value;
      let sname = document.getElementById("sname").value;
      let vnumber = document.getElementById("vnumber").value;
      let odl = document.getElementById("odl").value;
    
      var ok  =1;
    
      let cmd = document.getElementById("cmd").value;
    
      if(ok==1){
        
        axios.post(APIurl,{ 
                cmd: cmd,
                venue:fnames,
                time:sname,
                date:vnumber,
                username:GlobalUser,
                type:odl
                }
                )
            .then((response) => {
            
              console.log(response.data);
              document.getElementById("register_form").reset();
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                setLogingmsg(res["data"]);
                  setColors(" text-white bg-success");
                  setTimeout(()=>{
                   
                    // eslint-disable-next-line no-restricted-globals
                  location.href = rootUrl + "/receipts/attendance";
                 // location.ref = ;
                },1500);
                
                
                  
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
              // eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
                // Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Add New Meeting Attendance </h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
            <div className='register-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' id='register_form' action='process/login' onSubmit={process} >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='addattendance' hidden/>
                        <h4 className="theme-color ">Fill in Meeting form below</h4>
                        <hr />
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Venue: </b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' name="fnames" id="fnames" type="text" required/>             
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Date:</b>
                            </div>
                            <div className='col-md-6'>
                            <input   type="date" className='form-control' name="vnumber" id="vnumber" required/>
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Time:</b>
                            </div>
                            <div className='col-md-6'>
                            <input  type="time" className='form-control' name="sname" id="sname" required/>
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Meeting Type:</b>
                            </div>
                            <div className='col-md-6'>
                            <select name="nationality" id="odl" className='form-control text-center'>
                                        <option value="">-- select one --</option>
                                        <option value="Drivers Meeting">Drivers Meeting</option>
                                        <option value="Owners Meeting">Owners Meeting</option>
                                        <option value="Freelancers Meeting">Freelancers Meeting</option>
                                        <option value="Marshalls Meeting">Marshalls Meeting</option>
                                        </select>
                            </div>
                        </div>
                       <div className='form-group my-2 mx-auto w-md-50'>
                            <ButtonInput value = "Add receipt" active="btn-deactive my-3" />
                       </div>
                    </form>
            </div>
       </Container>

        </>
    );
}


import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Table,Card} from 'react-bootstrap';
import { Outlet, Link, Navigate } from "react-router-dom";
import './index.scss';
import Button  from "../../common/Button";
import axios from 'axios'
import {APIurl,BaseAPIurl, GlobalUser} from '../../App';
import TableUser from '../../components/TableUser'
import MSGDialogue from '../../components/Dialogue';


function Burial(props){
  return (
    <Card style={{ width: '18rem' }} className="m-1">
      <Card.Img variant="top" src={BaseAPIurl + props.url} />
      <Card.Body>
        <Card.Title>Burial Service</Card.Title>
        <Card.Text>
          Burials in the parish of <b>{props.title} {props.surname}</b>. <br/>
          In the year of <b>{props.date}</b> . <br/>
          Country of birth  <b>{props.nationality}</b>.
        </Card.Text>
        <Button title="Read more" active="btn-deactive my-3 receiptBtn"  />
       
        <span className='button border bg-danger rounded p-1 mr-1 ml-1'>
        <Link className='text-white small  hover' style={{whiteSpace: "nowrap"}} to={'deleteburial?id=' + props.id}><i className='fa fa-close mr-1'></i>Delete</Link>
       
        </span>
      </Card.Body>
    </Card>
  );

}

export default function Colleague(){

  const [members,setMember] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [totalMembers, setTotalMembers] = useState("0");
  const [freelancer, setFreelancer] = useState("0");
  const [LTOA, setLTOA] = useState("0");
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg13");


  
  var activated = (id,path)=>{
    let els =  document.getElementsByClassName("receiptBtn");

    for (let i=0; i<els.length-1;i++){
        els[i].classList.remove("btn-active");
        els[i].classList.add("btn-deactive");
    }

    
    document.getElementById(id).classList.remove("btn-deactive");
    document.getElementById(id).classList.add("btn-active");
   


// eslint-disable-next-line no-restricted-globals
// location.href =rootUrl  + path;

}
  
    
useEffect(() => {
  

    
//Get all members registered in the system
  axios.post(APIurl,{ 
      cmd: "getallburial",
      username:GlobalUser
      } )
      .then((response) => {

      var res = response.data;

      if(!res["success"]){   
          setLogingmsg(res["data"]);
          setColors("Could not send request");
      }
      else{

          console.log(res["data"]);

          setMember(res["data"]);

          setTotalMembers(res["data"].length);
          
          setLogging(true);
          document.getElementById(diaglogueID).style.display = "block"; 
         
      }
     
      })
      .catch((error) => {

      console.log(error);
      
      });


}, []);
    

      var table_headers = [
                            {id:1,name:"ID"},{id:2,name:"Name "},{id:6,name:"Surname"},,{id:3,name:"Date of Death"},{id:4,name:"Nationality"},
                            {id:8,name:"Manage"}
    ];

    const receipts = [

      {id:11,title:"Registration Receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/register"},
      {id:12,title:"Award Receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/award"},
      {id:13,title:"Stand Receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/stand"},
      {id:14,title:"Colleagues Stand receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/colleagues"},
      {id:15,title:"Burial receipt",active:"btn-active my-3 receiptBtn",path:"/receipts/burial"},
     
      {id:17,title:"Meeting receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/attendance"},
    
    ]
    var buttons = [{id:4,title:"Add receipt",path:"addburial",icon:"fa fa-print mx-2"}]

    return (
        <>
         {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 

       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Manage Burial Receipts</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>

       </Container>
       <div className='bg-white mx-2 rounded-top box-shadow px-0 pt-0  py-0 position-relative top-0'>
         
          <div className='container-fluid bg-grey receipts overflow-auto rounded-top p-1 d-flex justify-content-center flex-row ' >

            {receipts.map((res)=><Button path={res.path} key={res.id} id={res.id} action={activated}   icon={res.icon} title = {res.title} active={res.active}  />)}
         
        </div>

        
<Container fluid className='d-flex table-header-home position-sticky left-0 m-0 p-0' >
    <div className="my-1 p-0 container animate__animated animate__fadeInLeft">
        <h4 style={{color:'grey'}}>Burial Receipts Lists</h4>
    </div>
   
    <div className='w-100 position-sticky right-0 export-btns my-1'>
   
   {buttons.map((button) =>  <Button key={button.id} icon={button.icon} path = {button.path} title={button.title} active="btn-deactive float-right animate__animated animate__fadeInRight" />)}

</div>
    <hr/>
</Container>
        <div className='container-fluid d-flex flex-wrap'> 
        {members.map((res)=><Burial title={res.name} key={res.id} surname={res.surname} id={res.id}   date = {res.date} nationality={res.Nationality} url={res.url}  />)}
         </div>

         </div> 
         </>
    );
}


import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap';
import { Outlet, Link, Navigate } from "react-router-dom";
import './index.scss';
import Button  from "../../common/Button";
import axios from 'axios'
import {APIurl, GlobalUser} from '../../App';
import TableUser from '../../components/TableUser'
import MSGDialogue from '../../components/Dialogue';

export default function Attendance(){

  const [members,setMember] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [totalMembers, setTotalMembers] = useState("0");
  const [freelancer, setFreelancer] = useState("0");
  const [LTOA, setLTOA] = useState("0");
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg13");


  
  var activated = (id,path)=>{
    let els =  document.getElementsByClassName("receiptBtn");

    for (let i=0; i<els.length-1;i++){
        els[i].classList.remove("btn-active");
        els[i].classList.add("btn-deactive");
    }

    
    document.getElementById(id).classList.remove("btn-deactive");
    document.getElementById(id).classList.add("btn-active");
   


// eslint-disable-next-line no-restricted-globals
// location.href =rootUrl  + path;

}
  
    
useEffect(() => {
  

    
//Get all members registered in the system
  axios.post(APIurl,{ 
      cmd: "getallmeetings",
      username:GlobalUser
      
      } )
      .then((response) => {

      var res = response.data;

      if(!res["success"]){   
        
          setLogingmsg(res["data"]);
          setColors("Could not send request");
      }
      else{

          console.log("i'm done ",res["data"]);

          setMember(res["data"]);

          setTotalMembers(res["data"].length);
          
          setLogging(true);
          document.getElementById(diaglogueID).style.display = "block"; 
         
      }
     
      })
      .catch((error) => {
      console.log(error);
      });


}, []);
    

      var table_headers = [
                            {id:1,name:"ID"},{id:2,name:"Type of meeting"},{id:3,name:"Meeting Date"},
                            {id:4,name:"Meeting Time"},{id:5,name:"Meeting Venue"},{id:8,name:"Manage"}
    ];

    const receipts = [
      {id:11,title:"Registration Receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/register"},
      {id:12,title:"Award Receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/award"},
      {id:13,title:"Stand Receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/stand"},
      {id:14,title:"Colleagues Stand receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/colleagues"},
      {id:15,title:"Burial receipt",active:"btn-deactive my-3 receiptBtn",path:"/receipts/burial"},
      {id:17,title:"Meeting receipt",active:"btn-active my-3 receiptBtn",path:"/receipts/attendance"},
    ];

    var buttons = [{id:4,title:"Add receipt",path:"addattendance",icon:"fa fa-print mx-2"}]

    return (
        <>
         {logging? <MSGDialogue msg = {loginmsg} id={diaglogueID}  colors= {colors} /> : ""} 

       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Manage Meeting Receipts</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>

       </Container>
       <div className='bg-white mx-2 rounded-top box-shadow px-0 pt-0  py-0 position-relative top-0'>
         
          <div className='container-fluid bg-grey receipts overflow-auto rounded-top p-1 d-flex justify-content-center flex-row ' >

            {receipts.map((res)=><Button path={res.path} key={res.id} id={res.id} action={activated}   icon={res.icon} title = {res.title} active={res.active}  />)}
         
        </div>
      
          <TableUser len={members.length} name="Meeting Receipts" edit="/editmeeting" delete="/deletemeeting" table_headers = {table_headers} members={members} buttons={buttons}/>
        
         </div> 
         </>
    );
}


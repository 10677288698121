

import React,{useEffect} from 'react';

import './index.scss';

import logo from "../../assets/img/logo-bg.png";

import Button from "../../common/Button";
import ButtonInput from '../../common/ButtonInput';
import axios from 'axios'
import { useState } from "react";
import MSGDialogue from '../../components/Dialogue';
import {APIurl,rootUrl,callcookie, getCookie} from '../../App';



import { Container, Form } from 'react-bootstrap';


function Parcel(props){

    return(<>
   
    <div className="container-fluid mx-0 my-4 px-2 py-2 bg-white">
        <div className="container border py-1">
            <h3>Is this item delivered? <b className="text-primary">{props.item[0].delivered} </b></h3>
            <hr/>
         <div className="row m-0 p-0">
            <div className="col-sm-4 mx-auto my-1 px-1">
            <div className="card m-0 p-0">
                <div className="card-title  text-center text-white pt-1 bg-color rounded">
                    <h3>Parcel Information</h3>
                    </div> 
                    <div className="card-body">
                        <ul>
                            <li>
                                <b> Parcel Name: </b> {props.item[0].pname}
                            </li>
                            <li>
                                <b> Parcel Description: </b> {props.item[0].description}
                            </li>
                            <li>
                                <b className='text-danger'> Parcel ID No: </b> {props.item[0].id}
                            </li>
                        </ul>
                    </div>
            </div>
        </div>
        <div className="col-sm-4 mx-auto my-1 px-1">
            <div className="card m-0 p-0">
            <div className="card-title  text-center text-white pt-1 bg-color rounded">
                    <h3>Delivery Information</h3>
                    </div> 
                    <div className="card-body">
                        <ul>
                            <li>
                                <b> Delivery Date: </b> {props.item[0].deliverydate}
                            </li>
                            <li>
                                <b> Pickup Date: </b> {props.item[0].pickupdate}
                            </li>
                            <li>
                                <b> Pickup Address: </b> {props.item[0].pickup}
                            </li>
                            <li>
                                <b> Delivery Address: </b> {props.item[0].Delivery}
                            </li>
                        </ul>
                    </div>
            </div>
        </div>
        <div className="col-sm-4 mx-auto my-1 px-1">
            <div className="card m-0 p-0">
            <div className="card-title  text-center text-white pt-1 bg-color rounded">
                    <h3>Owner's Information</h3>
                    </div> 
                    <div className="card-body">
                        <ul>
                            <li>
                                <b> Owner's Name: </b> {props.item[0].oname}
                            </li>
                        </ul>
                    </div>
            </div>
        </div>

    </div>
           

        </div>
    </div>
    
    </>)

}

export default function Tracking(props){

    
  const [loginmsg, setLogingmsg] = useState("");
  const [members,setMember] = useState([]);
  const [colors, setColors] = useState("");
  const [isSearched, setSearched] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);

    
    useEffect(() => {
        
    }, []);

    
    var process = (event)=>{
        event.preventDefault();
        setColors("text-white bg-secondary");
        setLogingmsg("Searching Parcel in the database, please don't close this tab....");
        setLogging(true);
        try {
            document.getElementById(diaglogueID).style.display = "block"; 
        } catch (error) {
            console.log("Message box is not found");
        }
        let id = document.getElementById("username").value;
        
    axios.post(APIurl,{ 
        cmd: "getparcelbyid",
        id: id
        } )
        .then((response) => {

        var res = response.data;
        console.log("status ",res["success"]);
        if(!res["success"]){
            setSearched(false);
            setLogingmsg("Parcel not found, please check entered ID number or Ref Number");
            setColors("text-white bg-danger");
            console.log("DATA for this parcel: ", res);
            if(res["success"]==null){
                setLogingmsg("Some error encountered");
            }
        }
        else{
            setLogingmsg("Information of this parcel is listed below");
            setColors("text-white bg-success");
            setMember(res["data"]);
            setSearched(true);
           

        }
        document.getElementById(diaglogueID).style.display = "block"; 
            setLogging(true);
        })
        .catch((error) => {
        console.log(error);
        });


     }





        return(
            <>
           {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
                
             
            <Container fluid className='p-3 bg-white h-100 position-relative top-0'>
                <Button title="Login to Dashborad" active="btn-deactive my-3 ml-2 position-absolute top-0 " path="/" /> 
              {isSearched? <Parcel item={members} /> : <div className='w-100 login-logo'><img src={logo}  alt="logo"/></div>}
               

                <div className='login-container mx-auto my-3 p-3 bg-secondary rounded animate__animated animate__fadeInDown'>
                    <form method='post' action='process/login' onSubmit={process}  >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='login' hidden/>
                        <h4 className="text-white text-center">Item Tracking</h4>
                        <hr className='bg-light'/>
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control' placeholder='Enter ID or Ref No' name="username" id="username" type="text" required/>
                            <span className='text-danger'>{}</span>
                        </div>
                      
                        <ButtonInput  title = "Track Parcel" active="btn-deactive my-3" />
                      
                    </form>
    
                </div>
                
            </Container>
            </>
        )
        ;
    

  

}


import React,{useEffect} from 'react';

import './index.scss';
import MSGDialogue from '../../components/Dialogue';
import {deletecookie} from '../../App';



export default function Logout(props){


    useEffect(() => {
          //eslint-disable-next-line no-restricted-globals
        if (confirm("Do you want to log out?")) {
            deletecookie("softransuser");
          }
        
        setTimeout(()=>{
             //eslint-disable-next-line no-restricted-globals
             var BaseURL = location.href;
                BaseURL = BaseURL.split("/");
                BaseURL = BaseURL[0];
                  //eslint-disable-next-line no-restricted-globals
                location.href = BaseURL + "/";
            
        },
            1000
            )
      }, []);


        return(
            <>
          <MSGDialogue msg = "You logged out sucessfully" id = "msg3"  colors= " text-white bg-success" />
            </>
        )
        ;
    

  

}
import React,{useEffect} from "react";
import "./index.css";
import {APIurl,callcookie} from '../../App';
  
const ToggleSwitch = ({ label,id }) => {
  useEffect(()=>{

  },[])
  
  const chnage = (id)=>{

  var el = document.getElementById(id);

  if(el.checked==true){
    el.checked = false;
    callcookie(id,"false",365);
  }
  else{
    el.checked = true;
    callcookie(id,"true",365);

  }
     
   

  };

  return (
    <div className="container text-left">
      {label}{" "}
      <div className="toggle-switch"  onClick={()=>{chnage(id)}}>
      <input type="checkbox" className="checkbox" name={label} id={id} />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};
  

export default ToggleSwitch;
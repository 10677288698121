import React,{useState,useEffect} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap';
import './index.scss';
import { Outlet, Link, Navigate } from "react-router-dom";
import Spinner from '../../common/Spinner';
import Button  from "../../common/Button";
import ExportButton from "../../common/ExportButton";
import {CSVLink, CSVDownload} from 'react-csv';



function Headers(props){
    return (
        <>
         <th style={{whiteSpace: "nowrap"}}>{props.name}</th>
        </>
    );
}


function Tdata(props){


    return(
        <td>{props.text}</td>
    );
}

function TRow(props){

    let data = [];
    for (let key in props.id) {
         data.push(props.id[key]); 
    }
    return (
        <>
        <tr className='text-secondary'>

      {data.map((member) => <Tdata key={member} text={member}  />)}
     
      <td className='d-flex flex-row'>

        <span className='button border bg-secondary rounded text-warning mr-1 ml-1 p-1'>
        <Link className='text-warning small' style={{whiteSpace: "nowrap"}} to={props.edit +'?id=' + props.ide}><i className='fa fa-pencil mr-1'></i> Edit</Link>
        </span>

        <span className='button border bg-danger rounded p-1 mr-1 ml-1'>
        <Link className='text-white small  hover' style={{whiteSpace: "nowrap"}} to={props.delete+'?id=' + props.ide}><i className='fa fa-close mr-1'></i>Delete</Link>
        </span> 

      </td>
    </tr>
        
        </>
    );
}


function RowReceipt(props){ 

    let data = [];
    var csvData = [];
    csvData.push(props.id);

    for (let key in props.id) {
         data.push(props.id[key]); 
    }

    return (
        <>
    <tr className='text-secondary'>
      {data.map((member) => <Tdata key={member} text={member}  />)}
      <td className='d-flex flex-row'>
      <CSVLink data={csvData}  className="mr-1"  style={{textDecoration:'none',whiteSpace: "nowrap"}} >
        <span className='button border bg-secondary rounded small text-warning mr-1 ml-1 p-1'>
        <i className='fa fa-print mr-1'></i> Print 
        </span>
        </CSVLink>
      </td>
    </tr>
        </>
    );

}

export default function TableUser(props){
     const [rows,setRows] = useState(10);
     const [numrows,setNumRows] = useState(0);
     const [loader1,setLoader] = useState(false);
     const [listrows,setListrows] = useState([]);
     const [search,setSearch] = useState("");
     const [isEmpty, setEmpty] = useState(false);

     const data = props.members;

     
   

    useEffect(()=>{
    
        setListrows(props.members);
        setNumRows(listrows.length);
        setLoader(true);
    
        if(listrows.length>0){
            setEmpty(false);
        }
        else{
            setEmpty(true);
        }
   

    },[props]);

    const  process = (event)=>{

        event.preventDefault();

        var temp_data =[];

        const database = data;

        let word = document.getElementById("tableSearch").value;
        if(word.length==0){
            word = " ";
        }
        word = word.toLowerCase();
      
        var words = word.split();
       
       
        for (let j=0; j<database.length; j++){
           
            var temp_str = "";

            for (const [key, value] of Object.entries(database[j])) {

                temp_str += value + " ";
                
              }

                temp_str = temp_str.toLowerCase();
            for (let i=0; i<words.length;i++){


                if(temp_str.includes(words[i])){
                    temp_data.push(database[j]);
                }

        }

        }
        

        setListrows(temp_data);
        setNumRows(temp_data.length);
        if(temp_data.length==0){
            setSearch("No match found");
        }
        else{
            setSearch("");
        }
        

    }


    return(

<Container fluid className='home-table rounded m-0 bg-white p-2 position-relative right-0 '>

<Container fluid className='d-flex table-header-home position-sticky left-0 m-0 p-0' >
    <div className="my-1 p-0 container animate__animated animate__fadeInLeft">
        <h4 style={{color:'grey'}}>{props.name}</h4>
    </div>
    <div className='w-100 position-sticky right-0 export-btns my-1'>
       
        <ExportButton csvData={listrows}  icon="fa fa-print mx-2"  title="Export CSV" active="btn-deactive float-right animate__animated animate__fadeInRight" /> 
        {props.buttons.map((button) =>  <Button key={button.id} icon={button.icon} path = {button.path} title={button.title} active="btn-deactive float-right animate__animated animate__fadeInRight" />)}
    </div>
    <hr/>
</Container>

<div  className='container-fluid position-relative  position-sticky left-0 top-0 p-0 form-group animate__animated animate__fadeInLeft my-1'>
<form method='post' id='register_form' action='process/login' onSubmit={process} >
<div className="input-group mb-3">
<input type="text" className='form-control ' placeholder='Type something here...'  name="Search"  
        id="tableSearch" />
        
  <div className="input-group-append">
    <input type="submit" className="input-group-text" id="basic-addon2" value="Search"/>
  </div>
</div>
     
</form>

</div>
{isEmpty?<div><h4 className='text-danger mx-4' >No data found, please add data!!</h4></div>:
<Table striped bordered hover className='animate__animated animate__zoomIn'>
<thead>
<tr>
{props.table_headers.map((name) => <Headers key={name.id} name={name.name}   />)}
</tr>
</thead>
<tbody>
{listrows.map((member) => <TRow key={member.id} edit={props.edit} delete={props.delete} id={member} ide={member.id} />)}
</tbody>

</Table>}


<h3 className='text-center text-danger'> {search} </h3>

{loader1? "" : <Spinner  />}

<div className="container my-3 text-left position-sticky left-0 ">
    <div className="form-group"> 
    <select name="rows" id="rows1" type="select" onChange={(event)=>{ 
    setRows(event.target.value);
   }} >
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="500">500</option>

    </select>
    <span className='m-0 p-0'> Showing {numrows} of {rows}  </span>
    </div>
        

</div>
</Container>

    );
}








export  function TableReceipt(props){
    const [rows,setRows] = useState(10);
    const [numrows,setNumRows] = useState(0);
    const [listrows,setListrows] = useState([]);
    const [loader1,setLoader] = useState(false);

    const [search,setSearch] = useState("");
    const [isEmpty, setEmpty] = useState(false);

    const data = props.members;

    


   

   useEffect(()=>{
   
    setListrows(props.members);
    setNumRows(listrows.length);
    setLoader(true);
    if(listrows.length>0){
        setEmpty(false);
    }
    else{
        setEmpty(true);
    }

  

   },[props]);

   const  process = (event)=>{

       event.preventDefault();

       var temp_data =[];

       const database = data;

       let word = document.getElementById("tableSearch").value;
       if(word.length==0){
           word = " ";
       }
       word = word.toLowerCase();
     
       var words = word.split();
      
      
       for (let j=0; j<database.length; j++){
          
           var temp_str = "";

           for (const [key, value] of Object.entries(database[j])) {

               temp_str += value + " ";
               
             }

               temp_str = temp_str.toLowerCase();
           for (let i=0; i<words.length;i++){


               if(temp_str.includes(words[i])){
                   temp_data.push(database[j]);
               }

       }

       }
       

       setListrows(temp_data);
       setNumRows(temp_data.length);
       if(temp_data.length==0){
           setSearch("No match found");
       }
       else{
           setSearch("");
       }
       

   }



return(

<Container fluid className='home-table rounded m-0 bg-white p-2 position-relative right-0'>

    <Container fluid className='d-flex table-header-home position-sticky left-0 m-0 p-0' >
        <div className="mr-1 p-0 container animate__animated animate__fadeInLeft">
            <h4 style={{color:'grey'}}>{props.name}</h4>
        </div>
        <div className='w-100 position-sticky right-0 export-btns'>
        <ExportButton csvData={listrows}  icon="fa fa-print mx-2"  title="Export CSV" active="btn-deactive float-right animate__animated animate__fadeInRight" /> 
        {props.buttons.map((button) =>  <Button key={button.id} icon={button.icon} path = {button.path} title={button.title} active="btn-deactive float-right animate__animated animate__fadeInRight" />)} </div>
        <hr/>
    </Container>  
    <div  className='container-fluid position-relative  position-sticky left-0 top-0 p-0 form-group animate__animated animate__fadeInLeft my-1'>
        <form method='post' id='register_form' action='process/login' onSubmit={process} >
        <div className="input-group mb-3">
        <input type="text" className='form-control ' placeholder='Type something here...'  name="Search"  
                id="tableSearch" />     
        <div className="input-group-append">
            <input type="submit" className="input-group-text" id="basic-addon2" value="Search"/>
        </div>
        </div> 
        </form>
    </div>
    {isEmpty?<div><h4 className='text-danger mx-4' >Do data found, please add data!!</h4></div>:
    <Table striped bordered hover className='animate__animated animate__zoomIn'>

        <thead>

            <tr>
            {props.table_headers.map((name) => <Headers key={name.id} name={name.name}   />)}
            </tr>
            
        </thead>

        <tbody>
            {listrows.map((member) => <RowReceipt key={member.id} edit={props.edit} delete={props.delete} id={member} ide={member.id} />)}
        </tbody>

    </Table>}


    <h3 className='text-center text-danger'> {search} </h3>
    {loader1? "" : <Spinner  />}

    <div className="container my-3 text-left position-sticky left-0 ">
    <div className="form-group"> 
    <select name="rows" id="rows1" type="select" onChange={(event)=>{ 
        setRows(event.target.value);
    }}>
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="30">30</option>
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="500">500</option>

    </select>
    <span> Showing {numrows} of {rows}  </span>
    </div>
        

    </div>

</Container>

   );
}
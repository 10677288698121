import React,{useState,useEffect} from 'react';
import {Container} from 'react-bootstrap';
import './index.scss';
import Button from "../../../common/Button";
import MSGDialogue from '../../../components/Dialogue';
import ButtonInput from "../../../common/ButtonInput";
import axios from 'axios';
import {APIurl,rootUrl,GlobalUser} from '../../../App';



export default function EditDriver(){

  const [username, setUsername] = useState("");
  const [vehicle, setVehicle] = useState("");
  const [Getcars,setGetcars] = useState("Loading cars...");
  const [cars, setCars] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [userID, setUserID] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);

    
useEffect(() => {
    //eslint-disable-next-line no-restricted-globals
    var id = location.href;

    id = id.split("id=");

    id  = id[1];

    axios.post(APIurl,{ 
        cmd: "getdriverbyid",
        id: id
        } )
        .then((response) => {

        var res = response.data;
        if(!res["success"]){
            setLogingmsg(res["data"]);
            setColors("text-white bg-danger");
            console.log("DATA for this user: ", res["data"]);
            if(res["success"]==null ){
                setLogingmsg("Some error encountered");
            }
           
        }
        else{
           
            setUsername(res["data"][0].username);
          
            setVehicle(res["data"][0].vehicle);
            setUserID(res["data"][0].id);

        }
            setLogging(true);
        })
        .catch((error) => {
        console.log(error);
        });


    //Get all cars registered for user in the system
    axios.post(APIurl,{ 
    cmd: "getallcars",
    username:GlobalUser
    } )
    .then((response) => {

        var res = response.data;

    if(!res["success"]){

        setLogingmsg(res["data"]);
        setColors("Could not get vehicles for this user");
        setLogging(true);
        document.getElementById(diaglogueID).style.display = "block";

    }
    else{
        setGetcars("");
        setCars(res["data"]);
    }
    })
    .catch((error) => {
    console.log(error);
    });

  }, []);
      
     
    var process = (event)=>{
        event.preventDefault();
      let username = document.getElementById("username").value;
     
      var ok  =1;
      let cmd = document.getElementById("cmd").value;
      let vehicle = document.getElementById("vehicle").value;
      let password = document.getElementById("password").value;
      
    
    
      if(ok==1){
         
         

          axios.post(APIurl,{ 
           
                    username: username,
                    pasword:password,
                    cmd: cmd,
                    vehicle:vehicle,
                    id:userID
              
        } )
            .then((response) => {
            
              console.log(response.data);
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-success");
                  setTimeout(()=>{
                   
                      // eslint-disable-next-line no-restricted-globals
                    location.href = rootUrl + "/managedriver";
                   // location.ref = ;
                  },1000);
                  
              
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
               //eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
             //Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Edit Driver Information</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='row container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
       <form method='post' className='col-sm-6 mx-auto' action='process/login' onSubmit={process} >
                   
                   <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='editdriver' hidden/>
                       <h4 className="theme-color  text-center">Edit Driver's Information</h4>
                       <hr />
                       <div className='form-group my-2 mx-1'>
                           <input className='form-control'  placeholder='Enter Username' name="username" id="username" defaultValue={username} autoComplete="new-password" type="text" required/>
                         
                       </div>
                     
                       <div className='form-group my-2 mx-1'>
                           <input className='form-control' placeholder='Enter New/Old Password' name="password" id="password"  type="password" autoComplete="new-password" />
                       </div>

                       <div className='form-group my-2 mx-1 row'>
                           <div className='col-md-5 pt-2 text-left px-0'>
                               <b>Vehicle for this driver:</b>
                           </div>
                           <div className='col-md-6'>
                           <select className="form-control text-left" name="memberType" id="vehicle" required>
                               <option value={vehicle}>{vehicle}</option>
                               {cars.map((member) => <option key={member.id} value={member.registration}> {member.name}({member.registration}) </option>)}
                               <option value=""> {Getcars}</option>
                           </select>
                           </div>
                       </div>
                       <ButtonInput value = "Save information" active="btn-deactive my-3" />
                     
                     
                   </form>
    
                </div>
       </Container>

        </>
    );
}


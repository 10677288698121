

import React,{useEffect} from 'react';

import './index.scss';

import logo from "../../assets/img/logo-bg.png";

import Button from "../../common/Button";
import axios from 'axios'
import { useState } from "react";
import MSGDialogue from '../../components/Dialogue';
import {APIurl,rootUrl,callcookie, getCookie} from '../../App';



import { Container, Form } from 'react-bootstrap';

export default function Login(props){

    
    useEffect(() => {
        
    if(getCookie("softransuser") != null){
        console.log(rootUrl);
        // eslint-disable-next-line no-restricted-globals
        location.href =rootUrl;
    }
    }, []);


    const [err_username, setUsername] = useState("");
    const [err_pasword, setPassword] = useState("");
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [diaglogueID, setdiaglogueID] = useState("msg1");
    const [logging, setLogging] = useState(false);

    var process = ()=>{
        
        let username = document.getElementById("username").value;
        var ok  =1;
        if(username.length==0){
        var ok  =0;
            
            setUsername("Username Cannot be empty");
        }
        else{
            setUsername("");
        }
        let pasword = document.getElementById("pasword").value;

        if(pasword.length==0){
        var ok  =0;

            setPassword("Password Cannot be empty");
        }
        else{
            setPassword("");
        }
        let cmd = document.getElementById("cmd").value;
        
        //console.log(username," ",pasword);

       
      
        if(ok==1){
           
           

            axios.post(APIurl,{ 
             
                      username: username,
                      pasword:pasword,
                      cmd: cmd
                
          } )
              .then((response) => {
              
                console.log(response.data);
                var res = response.data;
                if(!res["success"]){

                    setLogingmsg(res["data"]);
                    setColors(" text-white bg-danger");

                }
                else{
                    setLogingmsg("Login Successfully");
                    setColors(" text-white bg-success");
                    
                    callcookie("softransuser",username,1);
                    callcookie("softranstype",res["data"][2],1);
                    callcookie("softransdays",res["data"][3],1);
                    callcookie("status",res["data"][4],1);

                    setTimeout(()=>{
                // eslint-disable-next-line no-restricted-globals
                location.href = rootUrl;
                    },1000);
                }
                setLogging(true);
                document.getElementById(diaglogueID).style.display = "block"; 
                
                // eslint-disable-next-line no-restricted-globals
               //location.href = 'http://localhost:81/softrans/API.php';
              // Handle data
              
              })
              .catch((error) => {
                console.log(error);
              })
        }
        else{
            alert("Please fix errors!");
        }
    }

  

        return(
            <>
           {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
                
             
            <Container fluid className='p-3 bg-white h-100 position-relative top-0'>
                <Button title="Track Item" active="btn-deactive my-3 ml-2 position-absolute top-0 " path="/tracking" /> 
                <div className='w-100 login-logo'>
                    <img src={logo}  alt="logo"/>
    
                </div>
                <div className='login-container mx-auto my-3 p-3 bg-secondary rounded animate__animated animate__fadeInDown'>
                    <form method='post' action='process/login' >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='login' hidden/>
                        <h4 className="theme-color text-center">Admin Login</h4>
                        <hr />
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control' placeholder='Enter Username' name="username" id="username" type="text" required/>
                            <span className='text-danger'>{err_username}</span>
                        </div>
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control' placeholder='Enter Password' name="pasword" id="pasword" type="password" required/>
                            <span className='text-danger'>{err_pasword}</span>
                        </div>
                        <Button  title = "Sign In" active="btn-deactive my-3" action={process} />
                      
                    </form>
    
                </div>
                
            </Container>
            </>
        )
        ;
    

  

}
import React,{useState,useEffect} from 'react';
import {Container} from 'react-bootstrap';
import './index.scss';
import TableUser from '../../components/TableUser/driver.js';
import ButtonInput from "../../common/ButtonInput";
import MSGDialogue from '../../components/Dialogue';
import axios from 'axios';
import {APIurl,getCookie, GlobalType, GlobalUser} from '../../App';
import taxi from '../../assets/img/driver.png';





export default function Driver(){

  const [cars, setCars] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);
  const [Getcars,setGetcars] = useState("Loading cars...");
  const [members,setMember] = useState([]);
  var table_headers=[{id:1,name:"ID"},{id:2,name:"Username"},{id:3,name:"Password"},
  {id:5,name:"Vehicle Reg No:"},{id:4,name:"Manage"}];
 
     

    var buttons = [];

    
    var process = (event)=>{

      event.preventDefault();
      let username = document.getElementById("username").value;
      var ok  =1;
      let pasword = document.getElementById("pasword").value;
      let vehicle = document.getElementById("vehicle").value;
      let cmd = document.getElementById("cmd").value;
    
    
      if(ok==1){
        if(cars.length==0){
            setLogingmsg("Your vehicle list list is empty hence you can't add a driver, please add vehicle first");
            setColors("text-white bg-danger");
            setLogging(true);
            document.getElementById(diaglogueID).style.display = "block"; 

        }

        else{
            
            axios.post(APIurl,{ 
            
                username: username,
                pasword:pasword,
                vehicle:vehicle,
                cmd: cmd,
                owner:GlobalUser
            
        
                        } )
            .then((response) => {
            
            console.log(response.data);
            var res = response.data;
            if(!res["success"]){
                setLogingmsg(res["data"]);
                setColors(" text-white bg-danger");
            }
            else{
                setLogingmsg("Driver account created successfully");
                setColors(" text-white bg-success");
                console.log("data" + res["data"]);
                setMember(res["data"]);
                
                
            }
            setLogging(true);
            document.getElementById(diaglogueID).style.display = "block"; 
            // eslint-disable-next-line no-restricted-globals
            //location.href = 'http://localhost:81/softrans/API.php';
            // Handle data
            })
            .catch((error) => {
            console.log(error);
            })
            
        }
         
         

      }
      else{
          alert("Please fix errors!")
      }
  }
useEffect(()=>{

//Get all cars registered for user in the system

axios.post(APIurl,{ 
    cmd: "getallcars",
    username:GlobalUser
    } )
    .then((response) => {
    var res = response.data;

    if(!res["success"]){   
        setLogingmsg(res["data"]);
      
        setColors("Could not get vehicles for this user");
        setLogging(true);
        try{
         document.getElementById(diaglogueID).style.display = "block";
        }
        catch(error){
            console.log(error);
        }
        
    }
    else{

        setGetcars("");
        setCars(res["data"]);
       
    }
   
    })
    .catch((error) => {

    console.log(error);
    
    });

    
//Get all drivers registered for user in the system

axios.post(APIurl,{
    cmd: "getalldrivers",
    username:GlobalUser
    } )
    .then((response) => {
    var res = response.data;

    if(!res["success"]){  
       
        setLogingmsg(res["data"]);
      
        setColors("Could not get drivers for this user");
        setLogging(true);
        try{
            document.getElementById(diaglogueID).style.display = "block";
        }
        catch(error){
            console.log(error);
        }
    }
    else{
        console.log("Drivers ",res["data"]);
        setMember(res["data"]);
            
       
    }
   
    })
    .catch((error) => {

    console.log(error);
    
    });



}, []);


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Manage Drivers</h3></div>
       <div className='container-fluid m-0 text-center bg-white'>
       <img src={taxi} className='w-50 mx-auto '/>
       </div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='row container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' className='col-sm-6 mx-auto' action='process/login' onSubmit={process} >
                   
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='adddriver' hidden/>
                        <h4 className="theme-color  text-center">Add New Driver</h4>
                        <hr />
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control'  placeholder='Enter Username' name="username" id="username" autoComplete="new-password" type="text" required/>
                          
                        </div>
                      
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control' placeholder='Enter Password' name="pasword" id="pasword" type="password" autoComplete="new-password" required/>
                           
                        </div>

                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5 pt-2 text-left px-0'>
                                <b>Vehicle for this driver:</b>
                            </div>
                            <div className='col-md-6'>
                            <select className="form-control text-left" name="memberType" id="vehicle" required>
                                <option value="">----chooose Vehicle----</option>
                                {cars.map((member) => <option key={member.id} value={member.registration}> {member.name}({member.registration}) </option>)}
                                <option value=""> {Getcars}</option>
                            </select>
                            </div>
                            
                        </div>
                        <ButtonInput value = "Add Driver" active="btn-deactive my-3" />
                      
                      
                    </form>
    
                </div>
       </Container>  

        <TableUser len={members.length} name="Drivers" user={GlobalUser} edit='editdriver' delete="/driverdelete" table_headers={table_headers} members={members} buttons={buttons}/>
        </>
    );
}


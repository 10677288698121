import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap';
import './index.scss';
import { Link } from "react-router-dom";
import Button  from "../../../common/Button";
import axios from 'axios'
import {APIurl,getCookie, GlobalDays, GlobalType, GlobalUser} from '../../../App';
import TableUser from '../../../components/TableUser'
import MSGDialogue from '../../../components/Dialogue';
import { PieChart } from 'react-minimal-pie-chart';

 //eslint-disable-next-line no-restricted-globals
 var id = location.href;
 id = id.split("id=");
 id  = id[1];
 const currentDriver = id;

function DashCards(props){
    return (
        <>
        <div className="stats-card my-2 bg-white box-hover transition-all-1s animate__animated animate__fadeInDown">
            <div className="stats-title mb-4">{props.title}</div>
           
          <div className="stats-content d-flex  flex-row">
            <div className='theme-color font-weight-bold m-1 stats-icon'>
           <i className="fa fa-line-chart"></i>
            </div>
            <div className='stat-nums m-1'><b>{props.number}</b></div>
            <div className='stats-bar m-1'></div>

          </div>
        </div>
        </>
    );
}

export default function Dashboard(){
  



  const [members,setMember] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [totalMembers, setTotalMembers] = useState("0");
  const [freelancer, setFreelancer] = useState(0);
  const [users, setUsers] = useState(0);
  const [LTOA, setLTOA] = useState("0");
  const [totalcost, setTotalCost] = useState(1);
  const [totalfuel, setCleaning] = useState(0);
  const [totalservice, setVisitor] = useState(0);
  const [totalmeetings, setMeetings] = useState(0);
  const [totalsalary, setSalary] = useState(0);
  const [totalother, setOther] = useState(0);
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg548");
  

  useEffect(() => {
    var datePicker = document.getElementById("ToDate");
  var currentDate = new Date();
  
  var dateString = currentDate.getFullYear() + "-" + 
                   (currentDate.getMonth() + 1).toString().padStart(2, "0") + "-" + 
                   currentDate.getDate().toString().padStart(2, "0");
  
  datePicker.value = dateString;

  datePicker = document.getElementById("FromDate");
  currentDate = new Date();
  
  var thirtyDaysAgo = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
  
   dateString = thirtyDaysAgo.getFullYear() + "-" + 
                   (thirtyDaysAgo.getMonth() + 1).toString().padStart(2, "0") + "-" + 
                   thirtyDaysAgo.getDate().toString().padStart(2, "0");
  
  datePicker.value = dateString;
    
    //Get all members registered in the system
  var FromDate = document.getElementById("FromDate").value;
  var ToDate = document.getElementById("ToDate").value;

      axios.post(APIurl,{ 
          cmd: "getallcosts",
          from: FromDate,
          to : ToDate,
          username:currentDriver
          
          } )
          .then((response) => {
    
          var res = response.data;
         
    
          if(!res["success"]){   
            console.log(response.data);
              setLogingmsg("Error Encountered: ");
              setColors("Could not send request");
          }
          else{
    
              console.log(res["data"]);
    
             // setMember(costs);

              setMember(res["data"]);

               var total = 0;
               var cleaning = 0;
              var Salary = 0;
              var Meetings = 0;
              var visitors = 0;
              var other = 0;
               for (let i=0; i<res["data"].length; i++) {
    
                 total += parseFloat(res["data"][i].cost);
                 if(res["data"][i].category == "Fuel"){
                   cleaning += parseFloat(res["data"][i].cost);
                 }
                 else if( res["data"][i].category == "Traffic"){
                   Salary += parseFloat(res["data"][i].cost);
                   console.log("I'm here");
                 }
                 else if( res["data"][i].category == "Service"){
                   Meetings += parseFloat(res["data"][i].cost);
                 }
                 else if( res["data"][i].category == "Collection"){
                   visitors += parseFloat(res["data"][i].cost);
                 }
                 else{
                   other += parseFloat(res["data"][i].cost);
                 }
               }
             
              setTotalCost(total);
              setLTOA(cleaning);
              cleaning = (cleaning/total)*100
              setCleaning(cleaning.toFixed(2));
              setTotalMembers(visitors);
              visitors = (visitors/total)*100
              setVisitor(visitors.toFixed(2));
              setFreelancer(Meetings);
              Meetings = (Meetings/total)*100
              setMeetings(Meetings.toFixed(2));
              Salary = (Salary/total)*100
              setSalary(Salary.toFixed(2));
              other = (other/total)*100
              setOther(other.toFixed(2));

             
              
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 

             
          }
         
          })
          .catch((error) => {
    
          console.log(error);
          
          });
    
    
    }, []);
    

var process = ()=>{

  var datePicker = document.getElementById("ToDate");
  var currentDate = new Date();
  
  var dateString = currentDate.getFullYear() + "-" + 
                   (currentDate.getMonth() + 1).toString().padStart(2, "0") + "-" + 
                   currentDate.getDate().toString().padStart(2, "0");
  
  datePicker.value = dateString;

  datePicker = document.getElementById("FromDate");
  currentDate = new Date();
  var timeframe = document.getElementById("timeframe").value;
  if(timeframe == "Yearly"){
    var thirtyDaysAgo = new Date(currentDate.getTime() - 365 * 24 * 60 * 60 * 1000);

  }
  else if(timeframe == "Weekly"){
    var thirtyDaysAgo = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);

  }
  
  else if(timeframe == "Daily"){
    var thirtyDaysAgo = new Date(currentDate.getTime() - 1 * 24 * 60 * 60 * 1000);

  }
  else{
    var thirtyDaysAgo = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
  }
  
  
   dateString = thirtyDaysAgo.getFullYear() + "-" + 
                   (thirtyDaysAgo.getMonth() + 1).toString().padStart(2, "0") + "-" + 
                   thirtyDaysAgo.getDate().toString().padStart(2, "0");
  
  datePicker.value = dateString;
    
    //Get all members registered in the system
  var FromDate = document.getElementById("FromDate").value;
  var ToDate = document.getElementById("ToDate").value;
 
      axios.post(APIurl,{ 
          cmd: "getallcosts",
          from: FromDate,
          to : ToDate,
          username:currentDriver
          
          } )
          .then((response) => {
    
          var res = response.data;
         
    
          if(!res["success"]){   
            console.log(response.data);
              setLogingmsg("Error Encountered: ");
              setColors("Could not send request");
          }
          else{
    
              console.log(res["data"]);
    
             // setMember(costs);

              setMember(res["data"]);

               var total = 0;
               var cleaning = 0;
              var Salary = 0;
              var Meetings = 0;
              var visitors = 0;
              var other = 0;
               for (let i=0; i<res["data"].length; i++) {
    
                 total += parseFloat(res["data"][i].cost);
                 if(res["data"][i].category == "Fuel"){
                   cleaning += parseFloat(res["data"][i].cost);
                 }
                 else if( res["data"][i].category == "Traffic"){
                   Salary += parseFloat(res["data"][i].cost);
                   console.log("I'm here");
                 }
                 else if( res["data"][i].category == "Service"){
                   Meetings += parseFloat(res["data"][i].cost);
                 }
                 else if( res["data"][i].category == "Collection"){
                   visitors += parseFloat(res["data"][i].cost);
                 }
                 else{
                   other += parseFloat(res["data"][i].cost);
                 }
               }
             
              setTotalCost(total);
              setLTOA(cleaning);
              cleaning = (cleaning/total)*100
              setCleaning(cleaning.toFixed(2));
              setTotalMembers(visitors);
              visitors = (visitors/total)*100
              setVisitor(visitors.toFixed(2));
              setFreelancer(Meetings);
              Meetings = (Meetings/total)*100
              setMeetings(Meetings.toFixed(2));
              Salary = (Salary/total)*100
              setSalary(Salary.toFixed(2));
              other = (other/total)*100
              setOther(other.toFixed(2));

             
              
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 

             
          }
         
          })
          .catch((error) => {
    
          console.log(error);
          
          });
}


var process1 = ()=>{

    
    //Get all members registered in the system
  var FromDate = document.getElementById("FromDate").value;
  var ToDate = document.getElementById("ToDate").value;
 
      axios.post(APIurl,{ 
          cmd: "getallcosts",
          from: FromDate,
          to : ToDate,
          username:currentDriver
          
          } )
          .then((response) => {
    
          var res = response.data;
         
    
          if(!res["success"]){   
            console.log(response.data);
              setLogingmsg("Error Encountered: ");
              setColors("Could not send request");
          }
          else{
    
              console.log(res["data"]);
    
             // setMember(costs);

              setMember(res["data"]);

               var total = 0;
               var cleaning = 0;
              var Salary = 0;
              var Meetings = 0;
              var visitors = 0;
              var other = 0;
               for (let i=0; i<res["data"].length; i++) {
    
                 total += parseFloat(res["data"][i].cost);
                 if(res["data"][i].category == "Fuel"){
                   cleaning += parseFloat(res["data"][i].cost);
                 }
                 else if( res["data"][i].category == "Traffic"){
                   Salary += parseFloat(res["data"][i].cost);
                   console.log("I'm here");
                 }
                 else if( res["data"][i].category == "Service"){
                   Meetings += parseFloat(res["data"][i].cost);
                 }
                 else if( res["data"][i].category == "Collection"){
                   visitors += parseFloat(res["data"][i].cost);
                 }
                 else{
                   other += parseFloat(res["data"][i].cost);
                 }
               }
             
              setTotalCost(total);
              setLTOA(cleaning);
              cleaning = (cleaning/total)*100
              setCleaning(cleaning.toFixed(2));
              setTotalMembers(visitors);
              visitors = (visitors/total)*100
              setVisitor(visitors.toFixed(2));
              setFreelancer(Meetings);
              Meetings = (Meetings/total)*100
              setMeetings(Meetings.toFixed(2));
              Salary = (Salary/total)*100
              setSalary(Salary.toFixed(2));
              other = (other/total)*100
              setOther(other.toFixed(2));

             
              
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 

             
          }
         
          })
          .catch((error) => {
    
          console.log(error);
          
          });
}

const defaultLabelStyle = {
  fontSize: '5px',
  fill: '#fff',
  backgroundColor: '#fff',
  fontFamily: 'sans-serif',
};


const Cards = [
  {id: 1, title: 'Fuel Expenditure',number:LTOA},
  {id: 2, title: 'Car Service Expenditure',number:freelancer},
  {id: 3, title: 'Passangers & Parcels',number:totalMembers}
];

if(GlobalType=="Rank" || GlobalType=="owner"){
  Cards.push( {id: 4, title: 'Total Users',number:users}) ;

}


var table_headers = [
  {id:1,name:"ID"},{id:3,name:"Description"},{id:4,name:"Date"},
{id:5,name:"Total cost"},{id:6,name:"Category"},
{id:8,name:"Manage"}
];
    var buttons = [];
  
   
    var PieData = [];

    if(totalfuel !=0){
      PieData.push({ title: 'Fuel Expense', value: totalfuel, color: '#0016E0' });
    }
    if(totalmeetings !=0){
      PieData.push({title: 'Passangers/Parcel Expense', value: totalmeetings, color: '#FF0000' });
    }

    if(totalservice !=0){
      PieData.push({title: "Car Service Expense", value: totalservice, color: '#1c0235' });
    }
    
    if(totalsalary !=0){
      PieData.push({title: 'Traffic Fine Expense', value: totalsalary, color: '#962929'});
    }
    
    if(totalother !=0){
      PieData.push({title: 'Other Expense', value: totalother, color: '#000'});
    }

    return (
       
       <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 
        <div className='mr-0 ml-0 p-2 mt-2 mb-0 container-fluid'><h3> Dashboard For <b className='text-danger'>{currentDriver}</b>( Driver )</h3>

        <form method='post' className='col-sm-6 mx-0 container-fluid' action='process/login' onSubmit={process} >
          <div className='container-fluid row text-left mx-0 border p-1 bg-white ' > 

          <div className='col-sm-6 mx-0'><h4 className='text-primary mt-2'>Viewing data for: </h4></div>

          <div className='col-sm-4 mx-0'> 
          <select className='form-control' id="timeframe" onChange={process} defaultValue="Monthly">
            <option value = "Daily" >Daily</option>
            <option value = "Weekly" >Weekly</option>
            <option value = "Monthly" >Monthly</option>
            <option value = "Yearly" >Yearly</option>
          </select>
          </div>

          </div>
          </form>
          <div className='d-flex w-100  bg-white flex-wrap mt-3 mb-0 animate__animated animate__fadeInLeft'>
            <div className='form-group mx-2 d-flex flex-row mb-0 mt-1'> <b className='pt-2'>From</b> <input  defaultValue="2022-12-08" onChange={process1} type="date" className='form-control mx-2' id="FromDate" name="From"/></div>
            <div className='form-group mx-2 d-flex flex-row mb-0 mt-1'> <b className='pt-2'>To</b><input type="date" onChange={process1} className='form-control mx-2' defaultValue="2023-05-25" id="ToDate" name="To"/></div>
          </div>
          <hr />
          <div className='row m-0 p-0 bg-white'>
            <div className='col-sm-6 p-1'>
     
            <PieChart className='pie animate__animated animate__fadeIn'
              data={PieData}
              label={({ dataEntry }) => dataEntry.value + "%"}
              labelPosition = {60}
              lineWidth = {60}
              totalValue = {100}
              paddingAngle = {0}
              labelStyle={{
                ...defaultLabelStyle,
              }}
            />
            </div>
            <div className='col-sm-6 p-1 animate__animated animate__fadeIn'>
              <div className='card p-2'>
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#0016E0'}}></span>
                  <span className='mx-3 bold' >Fuel Expense</span>
                </div>
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#1c0235'}}></span>
                  <span className='mx-3 bold' >Passangers/Parcels collection Expense</span>
                </div>
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#FF0000'}}></span>
                  <span className='mx-3 bold' > Service Expense</span>
                </div>
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#962929'}}></span>
                  <span className='mx-3 bold' >Traffic Fines Expense</span>
                </div> 
                <div className='d-flex flex-row p-1'>
                  <span className='block' style={{width:25,height:25,background:'#000'}}></span>
                  <span className='mx-3 bold' >Other Expense</span>
                </div>
              </div>

              <div className='form-group my-2 animate__animated animate__fadeInUp'>
              <span className='h4'>Total: R{totalcost.toFixed(2)}</span>
            </div>

            </div>
            
          </div>
          <hr />


       

        </div>
        <Container fluid className='d-flex  my-4 w-100 home-cards'>
        {Cards.map((card) => <DashCards key={card.id} title={card.title} number ={card.number}  />)}
        </Container>
        <br/>
        <TableUser len={members.length}  name="My Expenditure" edit="" delete="" table_headers = {table_headers} members={members} buttons={buttons}/>
        </>

    );
}


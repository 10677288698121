import React from 'react';

import './index.scss';


var closeDialogue = (id)=>{

        
    document.getElementById(id).style.display = "none";

}

export default function MSGDialogue(props){
    var Icon = ()=>{
        return (<i className='fa fa-close  text-white' ></i>);
    };
    var Line = ()=>{
        return ( <hr/>);
    };
    var Icon1 = ()=>{
        return (<i className='fa fa-bell  text-warning'> </i>);
    };
    var notes = "New notification";
    if(props.msg.length==0){
        Icon =  ()=>{
            return (<></>);
        };
        Icon1 =  ()=>{
            return (<></>);
        }; Line = ()=>{
            return ( <></>);
        };
     notes = "";

    }
    return (<>
    <div id={props.id}  className={'msg-diaglogue rounded p-2 position-fixed animate__animated animate__headShake animate__repeat-3	 ' + props.colors} >
      <span className="msd-close hover" onClick={()=>{closeDialogue(props.id)}}>
            <Icon />
        </span>
        <span className="msd-notes  text-warning">
            <Icon1 /> <b>{notes}</b>
        </span>
       <Line />
        <p className='m-0 p-0'>
            {props.msg}
        </p>
       

    </div>
    </>);
}
import React,{useState,useEffect} from 'react';
import {Container} from 'react-bootstrap';
import './index.scss';
import TableUser from '../../components/TableUser/user.js';
import ButtonInput from "../../common/ButtonInput";
import MSGDialogue from '../../components/Dialogue';
import axios from 'axios';
import {APIurl,getCookie, GlobalType, GlobalUser} from '../../App';



function Type(){
    return(
        <>
          <div className='form-group my-2 mx-1'>
                        <label  className="form-label">Account Type</label>
                           <select className='form-control' id="type">
                            <option value="">---Select type of account</option>
                            <option value="Individual">Individual</option>
                            <option value="Rank">Rank</option>
                           </select>
          </div>

          <div className='form-group my-2 mx-1'> 
                        <label  className="form-label">Number of Trials days</label>
                        <div className='input-group'>
                            <input className='form-control' placeholder='Enter number of days' name="days" id="days" type="number" autoComplete="new-password" required/>
                        
                            <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon2">
                              Days
                              </span>
                            </div>
                        </div>
                        </div>
        </>
    )
}

function NoType(){
    return(
        <>
        <select className='form-control' id="type" hidden>
        <option value="Individual">Individual</option>
        </select>
        <input className='form-control' placeholder='Enter number of days' defaultValue={30} name="days" id="days" type="number" autoComplete="new-password" hidden/>
        </>
    )
}


export default function Adduser(){

  const [err_username, setUsername] = useState("");
  const [err_pasword, setPassword] = useState("");
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);
  const [Rank, setRank] = useState(false);
  const [trial, setTrial] = useState(false);
  const [members,setMember] = useState([]);
  var table_headers=[{id:1,name:"ID"},{id:2,name:"Username"},{id:3,name:"Password"},
  {id:5,name:"Role"},{id:6,name:"Branch Name"},{id:7,name:"Location"},{id:8,name:"Status"},{id:9,name:"Type"},{id:4,name:"Manage"}];
 

    
useEffect(() => {
   

    if(GlobalType=="owner"){
    setRank(true);
    }
    axios.post(APIurl,{ 
        cmd: "getallusers",
        owner:GlobalUser,
        type:GlobalType
        
        } )
        .then((response) => {

        var res = response.data;
        if(!res["success"]){
            setLogingmsg(res["data"]);
            setColors("Could not send request");
        }
        else{
            //console.log(res["data"], " size is ", res["data"].length);
            setMember(res["data"]);
        }
       
        })
        .catch((error) => {
        console.log(error);
        })

  }, []);
      
     

    var buttons = [];

    
    var process = (event)=>{
        event.preventDefault();
      let username = document.getElementById("username").value;
      let branch = document.getElementById("branch").value;
      let role = document.getElementById("role").value;
      let days = document.getElementById("days").value;
      let d = new Date();
      days = (d.getTime()) + Math.round(days*24*60*60*1000);
      let type = document.getElementById("type").value;
      let address = document.getElementById("address").value;
      var ok  =1;
      if(username.length==0){

      var ok  =0;

          setUsername("Username Cannot be empty");
      }
      else{
          setUsername("");
      }
      let pasword = document.getElementById("pasword").value;

      if(pasword.length==0){
      var ok  =0;

          setPassword("Password Cannot be empty");
      }
      else{
          setPassword("");
      }
      let cmd = document.getElementById("cmd").value;
    
    
      if(ok==1){
        if(members.length>3 && GlobalType=="Rank"){
            setLogingmsg("You cannot add more than 10 users as a rank");
            setColors("text-white bg-danger");
            document.getElementById(diaglogueID).style.display = "block"; 

        }

        else{
            
            axios.post(APIurl,{ 
            
                username: username,
                pasword:pasword,
                branch:branch,
                role:role,
                cmd: cmd,
                days:days,
                type:type,
                otype:GlobalType,
                address:address,
                owner:GlobalUser
            
        
                        } )
            .then((response) => {
            
            console.log(response.data);
            var res = response.data;
            if(!res["success"]){
                setLogingmsg(res["data"]);
                setColors(" text-white bg-danger");
            }
            else{
                setLogingmsg("User created successfully");
                setColors(" text-white bg-success");
                console.log("data" + res["data"]);
                setMember(res["data"]);
                
                
            }
            setLogging(true);
            document.getElementById(diaglogueID).style.display = "block"; 
            // eslint-disable-next-line no-restricted-globals
            //location.href = 'http://localhost:81/softrans/API.php';
            // Handle data
            })
            .catch((error) => {
            console.log(error);
            })
            
        }
         
         

      }
      else{
          alert("Please fix errors!")
      }
  }

console.log("members ", members);
const days = [];
for(let i=2; i<31;i++){
    days.push(i);

}
    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Manage Users</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='login-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' action='process/login' onSubmit={process} >
                   
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='adduser' hidden/>
                        <h4 className="theme-color  text-center">Add Sub-Admin Below</h4>
                        <hr />
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control'  placeholder='Enter Username' name="username" id="username" autoComplete="new-password" type="text" required/>
                            <span className='text-danger'>{err_username}</span>
                        </div>
                      
                        <div className='form-group my-2 mx-1'>
                            <input className='form-control' placeholder='Enter Password' name="pasword" id="pasword" type="password" autoComplete="new-password" required/>
                            <span className='text-danger'>{err_pasword}</span>
                        </div>

                        <div className='form-group my-2 mx-1'>
                        <label  className="form-label">Role</label>
                            <input className='form-control' placeholder='Eg: Manager' name="role" id="role" type="text" required/>
                        </div>
                        <div className='form-group my-2 mx-1'>
                        <label className="form-label">Rank Address</label>
                            <textarea className='form-control' placeholder='Enter Physical address of your rank' name="address" id="address" type="text" required></textarea>
                        </div>
                        {Rank? <Type  /> : <NoType/>}   

                       
                       
                        <div className='form-group my-2 mx-1'>
                            <label className="form-label">User Branch Name</label>
                            <input className='form-control' placeholder='E.g: MTN Taxi Rank' name="branch" id="branch" type="text" autoComplete="new-password" required/>
                        </div>
                        <ButtonInput value = "Add User" active="btn-deactive my-3" />
                      
                      
                    </form>
    
                </div>
       </Container>
       {Rank?  "" : <div className='text-danger'><b>NB:</b> You can only add 10 users as a Rank</div>}   
      

        <TableUser len={members.length} name="Users" user={GlobalUser} edit='edituser' delete="/userdelete" table_headers={table_headers} members={members} buttons={buttons}/>
        </>
    );
}


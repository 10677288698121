import React,{useState,useEffect} from 'react';
import {Container} from 'react-bootstrap';
import './index.scss';
import Button from "../../../common/Button";
import MSGDialogue from '../../../components/Dialogue';
import ButtonInput from "../../../common/ButtonInput";
import axios from 'axios';
import {APIurl,rootUrl,GlobalUser} from '../../../App';



export default function EditParcel(){
  
  const [username, setUsername] = useState("");
  const [oname, setOname] = useState("");
  const [deliverydate, setDeliverydate] = useState("");
  const [description, setDescription] = useState("");
  const [pickupdate, setPickupdate] = useState("");
  const [Delivery, setDelivery] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [pickup, setPickup] = useState("");
  const [email, setEmail] = useState("");
  const [pname, setPname] = useState("");
  const [loginmsg, setLogingmsg] = useState("");
  const [userID, setUserID] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);


    
useEffect(() => {
    //eslint-disable-next-line no-restricted-globals
    var id = location.href;
    id = id.split("id=");
    id  = id[1];
    axios.post(APIurl,{ 
        cmd: "getparcelbyid",
        id: id
        } )
        .then((response) => {

        var res = response.data;
        if(!res["success"]){
            setLogingmsg(res["data"]);
            setColors("text-white bg-danger");
            console.log("DATA for this parcel: ", res);
            if(res["success"]==null){
                setLogingmsg("Some error encountered");
            }
        }
        else{
          
            setUsername(res["data"][0].username);
            setOname(res["data"][0].oname);
            setDeliverydate(res["data"][0].deliverydate);
            setDescription(res["data"][0].description); 
            setPickupdate(res["data"][0].pickupdate);
            setDelivery(res["data"][0].Delivery);
            setCellphone(res["data"][0].cellphone);
            setPickup(res["data"][0].pickup);
            setEmail(res["data"][0].email);
            setPname(res["data"][0].pname);
          
            setUserID(res["data"][0].id);

        }
            setLogging(true);
        })
        .catch((error) => {
        console.log(error);
        });


  

  }, []);
      
     
    var process = (event)=>{
        event.preventDefault();
        setColors("text-white bg-secondary");
        setLogingmsg("Saving changes, Don't leave this window");
        setLogging(true);
        try {
            document.getElementById(diaglogueID).style.display = "block"; 
        } catch (error) {
            console.log("Message box is not found");
        }
        let pname = document.getElementById("pname").value;
        let oname = document.getElementById("oname").value;
        let cellphone = document.getElementById("cellphone").value;
        let email = document.getElementById("email").value;
        let description = document.getElementById("description").value;
        let pickup = document.getElementById("pickup").value;
        let Delivery = document.getElementById("Delivery").value;
        let pickupdate = document.getElementById("pickupdate").value;
        let deliverydate = document.getElementById("deliverydate").value;
        var ok  =1;
      
        let cmd = document.getElementById("cmd").value;
    
    
      if(ok==1){

          axios.post(APIurl,{ 
            cmd: cmd,
            pname:pname,
            oname:oname,
            cellphone:cellphone,
            email:email,
            pickup:pickup,
            Delivery:Delivery,
            pickupdate:pickupdate,
            deliverydate:deliverydate,
            description:description,
            id:userID
        } )
            .then((response) => {
            
              console.log(response.data);
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-success");
                  setTimeout(()=>{
                   
                      // eslint-disable-next-line no-restricted-globals
                    location.href = rootUrl + "/parcels";
                   // location.ref = ;
                  },1000);
                  
              
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
               //eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
             //Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Edit Parcel Information</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='row container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
       <form method='post' className='col-sm-10 mx-auto' action='process/login' onSubmit={process} >
                   
                   <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='editparcel' hidden/>
                       <h4 className="theme-color  text-center">Edit Parcel Information</h4>
                       <hr />
                       
                       <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel Name:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' defaultValue={pname}   name="pname" id="pname" type="text" required/>
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel owner's Name:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  defaultValue={oname}  name="oname" id="oname" type="text" required/>
                            </div>
                        </div>
                        
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel owner's cellphone number:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="cellphone"  defaultValue={cellphone} id="cellphone" type="text" required/>
                            </div>
                        </div>
                        
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel owner's Email address:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="email"  defaultValue={email} id="email" type="email" required/>
                            </div>
                        </div>

                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Parcel Description:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control'   defaultValue={description} name="description" id="description" type="text" required></textarea>
                            </div>
                            
                        </div>

                        
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Pick Address:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control' rows={6}  defaultValue={pickup} placeholder="Physical Address where the driver pick up the parcel" name="pickup" id="pickup" type="text" required></textarea>
                            </div>
                            
                        </div>

                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Delivery Address:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control'  rows={6} name="Delivery"  defaultValue={Delivery} id="Delivery" placeholder="Physical Address where the driver will drop up the parcel to the owner" type="text" required></textarea>
                            </div>
                            
                        </div>

                       
                       
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Pickup Date:</b>
                            </div>
                            <div className='col-md-6'>
                            <input   type="date" className='form-control'  defaultValue={pickupdate} name="pickupdate" id="pickupdate" required/>
                            </div>
                            
                        </div>
                        
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Delivery Date:</b>
                            </div>
                            <div className='col-md-6'>
                            <input   type="date" defaultValue={deliverydate} className='form-control' name="deliverydate" id="deliverydate" required/>
                            </div>
                            
                        </div>
                        

                       <ButtonInput value = "Save Changes" active="btn-deactive my-3" />
                     
                     
                   </form>
    
                </div>
       </Container>

        </>
    );
}


import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import './index.scss';

import Button from "../../../common/Button";
import ButtonInput from "../../../common/ButtonInput";
import MSGDialogue from '../../../components/Dialogue';
import axios from 'axios'
import {APIurl,GlobalUser,rootUrl} from '../../../App';



export default function Register(){

  const [err_ocellphone, setOcellphone] = useState("");
  const [err_dcellphone, setdcellphone] = useState("");
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);
    
  const  process = (event)=>{
        event.preventDefault();
      let memberType = document.getElementById("memberType").value;
      let fnames = document.getElementById("fnames").value;
      let sname = document.getElementById("sname").value;
      let vnumber = document.getElementById("vnumber").value;
      let oid = document.getElementById("oid").value;
      let ocellphone = document.getElementById("ocellphone").value;
      let branch = document.getElementById("branch").value;
      let odl = document.getElementById("odl").value;
      let dcellphone = document.getElementById("dcellphone").value;
      let sid = document.getElementById("sid").value;
      let sfname = document.getElementById("sfname").value;
      var ok  =1;
    
    //Validate owner's cellphone number 
 
      var ok  =1;
      if(ocellphone.length<10){ //NaN

        var ok  =0;

          setOcellphone("Cell Phone number is too short");

      }
      else if(parseInt(ocellphone) == NaN){

        setOcellphone("Please Enter Valid Cellphone number");

      }
      else{
        setOcellphone("");
      }
      //Validate driver's cellphone number 
   
      var ok  =1;
      if(dcellphone.length<10){ //NaN

        var ok  =0;

          setdcellphone("Cell Phone number is too short");

      }
      else if(parseInt(dcellphone) == NaN){

        setdcellphone("Please Enter Valid Cellphone number");

      }
      else{
        setdcellphone("");
      }


      let cmd = document.getElementById("cmd").value;
    
    
      if(ok==1){
          axios.post(APIurl,{ 
                    cmd: cmd,
                    memberType: memberType,
                    fnames:fnames,
                    sname:sname,
                    vnumber:vnumber,
                    oid:oid,
                    ocellphone:ocellphone,
                    branch:branch,
                    odl:odl,
                    dcellphone:dcellphone,
                    sfname:sfname,
                    sid:sid,
                    username:GlobalUser
        } )
            .then((response) => {
            
              console.log(response.data);
              document.getElementById("register_form").reset();
              var res = response.data;
              if(!res["success"]){
                  setLogingmsg(res["data"]);
                  setColors(" text-white bg-danger");
              }
              else{
                setLogingmsg(res["data"]);
                  setColors(" text-white bg-success");
                  setTimeout(()=>{
                   
                    // eslint-disable-next-line no-restricted-globals
                  location.href = rootUrl + "/dashboard";
                 // location.ref = ;
                },1500);
                
                 
                
                  
              }
              setLogging(true);
              document.getElementById(diaglogueID).style.display = "block"; 
              // eslint-disable-next-line no-restricted-globals
              //location.href = 'http://localhost:81/softrans/API.php';
                // Handle data
            })
            .catch((error) => {
              console.log(error);
            })
      }
      else{
          alert("Please fix errors!")
      }
  }


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Register new Member </h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='register-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
                    <form method='post' id='register_form' action='process/login' onSubmit={process} >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='addmember' hidden/>
                        <h4 className="theme-color ">Fill in registration form below</h4>
                        <hr />
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Member Type:</b>
                            </div>
                            <div className='col-md-6'>
                            <select className="form-control text-center" name="memberType" id="memberType" required>
                                <option value="">----chooose Member Type----</option>
                                <option value="Member of LTOA">Full-time Member</option>
                                <option value="Freelancer Member">Freelancer Member</option>
                            </select>
                            
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>First name(s):</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' placeholder='As it appears on your ID' name="fnames" id="fnames" type="text" required/>             
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Surname:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="sname" id="sname" type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Vehicle Registration No.:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="vnumber" id="vnumber" placeholder='This is owner vehicle'  type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Owner's ID No.:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="oid" id="oid" type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Owner's cellphone No.:</b>
                                <span className='text-danger'>{err_ocellphone}</span>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="ocellphone" id="ocellphone" type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Owner's drivers License:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="odl" id="odl" type="text" required/> 
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Branch Name:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control'  name="branch" id="branch" type="text" required/> 
                            </div>
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Driver's Cellphone No.:</b>
                                <span className='text-danger'>{err_dcellphone}</span>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' placeholder='eg: 072 810 8533'  name="dcellphone" id="dcellphone" type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Owner's Spouse Full Names:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' placeholder='If applicable'  name="sfname" id="sfname" type="text" /> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Owner's Spouse ID No.:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' placeholder='If applicable' name="sid" id="sid" type="text" /> 
                            </div>
                            
                        </div>
                       <div className='form-group my-2 mx-auto w-md-50'>
                       <ButtonInput value = "Add Member" active="btn-deactive my-3" />
                       </div>
                        
                      
                    </form>
    
                </div>
       </Container>

        </>
    );
}


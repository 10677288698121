import React,{useState,useEffect} from 'react';
import {Container,Row,Col} from 'react-bootstrap';
import './index.scss';

import Button from "../../../common/Button";
import ButtonInput from "../../../common/ButtonInput";
import MSGDialogue from '../../../components/Dialogue';
import axios from 'axios'
import {APIurl,rootUrl} from '../../../App';



export default function EditWorker(){

  const [err_ocellphone, setOcellphone] = useState("");
  const [member, setMember] = useState({});
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [diaglogueID, setdiaglogueID] = useState("msg4");
  const [logging, setLogging] = useState(false);


    
        
    useEffect(()=>{


    //eslint-disable-next-line no-restricted-globals
    var id = location.href;
    id = id.split("id=");
    id  = id[1];
    //Get member details from by ID in the database
    axios.post(APIurl,{ 
        cmd: "getworkerbyid",
        id:id
        
        } )
        .then((response) => {

        var res = response.data;

        if(!res["success"]){   
            setLogingmsg(res["data"]);
            setColors("text-white bg-danger");
        }
        else{

            console.log(res["data"]);
            setMember(res["data"][0]);
            setLogging(true);
            document.getElementById(diaglogueID).style.display = "block"; 
        
        }
    
        })
        .catch((error) => {

        console.log(error);
        
        });

    },[]);   


    //Now process the edited information about the member
        
    const  process = (event)=>{

        event.preventDefault();
    //eslint-disable-next-line no-restricted-globals
    var id = location.href;
    id = id.split("id=");
    id  = id[1];
    let name = document.getElementById("name").value;
    let surname = document.getElementById("surname").value;
    let role = document.getElementById("role").value;
    let postal_address = document.getElementById("postal_address").value;
    let phone = document.getElementById("phone").value;
    let town = document.getElementById("town").value;
    var ok  =1;

    //Validate owner's cellphone number 

    if(phone.length<10){ //NaN

        var ok  =0;

        setOcellphone("Cell Phone number is too short");

    }
    else if(parseInt(phone) == NaN){

        setOcellphone("Please Enter Valid Cellphone number");

    }
    else{
        setOcellphone("");
    }
    //Validate driver's cellphone number 

    
    
    let cmd = document.getElementById("cmd").value;


  if(ok==1){
     
      axios.post(APIurl,{ 
        cmd: cmd,
        id: id,      
        name:name,
        surname:surname,
        role:role,
        postal_address:postal_address,
        town:town,
        phone:phone
    } )
        .then((response) => {
        
          console.log(response.data);
          document.getElementById("register_form").reset();
          var res = response.data;
          if(!res["success"]){
              setLogingmsg(res["data"]);
              setColors(" text-white bg-danger");
          }
          else{
            setMember(res["data"][0]);
            setLogingmsg("Successfully Edited details of " + name);
              setColors(" text-white bg-success"); 
              setTimeout(()=>{
                   
                // eslint-disable-next-line no-restricted-globals
              location.href = rootUrl + "/worker";
             // location.ref = ;
            },1500);
          }
          setLogging(true);
          document.getElementById(diaglogueID).style.display = "block"; 
          // eslint-disable-next-line no-restricted-globals
          //location.href = 'http://localhost:81/softrans/API.php';
            // Handle data
        })
        .catch((error) => {
            console.log(error);
            })
    }
    else{
        alert("Please fix errors!")
    }
    }

      


    return (
        <>
        {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""}   
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Edit Member information</h3></div>
       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'>
       <div className='register-container mx-auto my-3 p-3 bg-white rounded animate__animated animate__fadeInDown'>
       <form method='post' id='register_form' action='process/login' onSubmit={process} >
                    <input className='form-control' name="cmd" id="cmd" type="text" defaultValue='editworker' hidden/>
                    
                    <h4 className="theme-color">Fill in form below</h4>
                        
                        <hr />
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Role:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' defaultValue={member.role} placeholder='eg:  Manager' name="role" id="role" type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>First name(s):</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' defaultValue={member.name} placeholder='As it appears on your ID' name="name" id="name" type="text" required/>             
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Surname:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' defaultValue={member.surname}  name="surname" id="surname" type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Postal Address:</b>
                            </div>
                            <div className='col-md-6'>
                            <textarea className='form-control' defaultValue={member.postal_address} placeholder='eg:  Jorrison Street'  name="postal_address" id="postal_address" type="text" required></textarea> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>Town:</b>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' defaultValue={member.town} placeholder='eg:  Johannesburg'  name="town" id="town" type="text" required/> 
                            </div>
                            
                        </div>
                        <div className='form-group my-2 mx-1 row'>
                            <div className='col-md-5'>
                                <b>cellphone No.:</b>
                                <span className='text-danger'>{err_ocellphone}</span>
                            </div>
                            <div className='col-md-6'>
                            <input className='form-control' defaultValue={member.phone} placeholder='eg: 072 810 8533'  name="phone" id="phone" type="text" required/> 
                            </div>
                            
                        </div>
                       
                       <div className='form-group my-2 mx-auto w-md-50'>
                       <ButtonInput value = "Add Worker" active="btn-deactive my-3" />
                       </div>
                        
                      
                    </form>
    
                </div>
       </Container>

        </>
    );
}


import React,{useEffect,useState} from 'react';
import './index.scss';
import { Link } from "react-router-dom";
import Button  from "../../common/Button";
import ButtonInput from "../../common/ButtonInput";
import axios from 'axios'
import {APIurl,rootUrl,BaseAPIurl, GlobalUser} from '../../App';
import MSGDialogue from '../../components/Dialogue';
import taxi from '../../assets/img/books.png';


function Book(props){
  let filename = props.name.split("/");
  filename = filename[1];
  return(
    <>
     <li className='p-1 m-2'>
              <a href={BaseAPIurl + props.name}>{filename} <Link className='btn border hover bg-danger text-white px-3 py-1 small text-small' to={"bookdelete?id=" + props.name} >Delete Book</Link></a>
     </li>
    </>
  )
 
}

export default function Inventory(){

  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [logging, setLogging] = useState(false);
  const [num_books, setBooks] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg19");
  const [members,setMember] = useState([]);
  

  const  process = (event)=>{
    event.preventDefault();
    let book = document.getElementById("book").files.item(0);
    setLogingmsg("Uploading book...Please don't close this window");
    setColors(" text-white bg-secondary");
    try {
      document.getElementById(diaglogueID).style.display = "block"; 
    } catch (error) {
      console.log(error);
    }
    
   
         
    axios.post(APIurl,{ 
              cmd: "addbook",
              book:book,
              username:GlobalUser    
  },{
    headers: {
      'Content-Type': 'multipart/form-data'
    }}
     )
      .then((response) => {
      
        console.log(response.data);
        document.getElementById("register_form").reset();
        var res = response.data;
        if(!res["success"]){
            setLogingmsg(res["data"]);
            setColors(" text-white bg-danger");
        }
        else{
          setLogingmsg(res["data"]);
            setColors(" text-white bg-success");

           setTimeout(()=>{
                // eslint-disable-next-line no-restricted-globals
              location.href = rootUrl + "/inventory";
             // location.ref = ;
            },1500);
        }
        try {
          document.getElementById(diaglogueID).style.display = "block"; 
        } catch (error) {
          console.log(error);
        }
        
       
      })
      .catch((error) => {
        console.log(error);
      })

  
  }
    
  useEffect(() => {

    
//Get all members registered in the system
axios.post(APIurl,{ 
  cmd: "getbooks",
  username:GlobalUser
  
  } )
  .then((response) => {
   
  var res = response.data;

  if(!res["success"]){   
    console.log("got it",res["data"]);
      setLogingmsg(res["data"]);
      setColors("Could not send request");
  }
  else{

      
      setMember(res["data"]);
      console.log("got it",res["data"]);
      setLogging(true);
            
      if(res["data"].length<=0){
        setBooks(true);
      }
      else{
        setBooks(false);
      }
      document.getElementById(diaglogueID).style.display = "block"; 
     
  }
 
  })
  .catch((error) => {

  console.log(error);
  
  });

}, []);
    

    var buttons = [{id:1,false:"Export CSV",path:"exportmembers"},{id:2,title:"Add receipt",path:"addreceipt"}]

    return (
        <>
         {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 
       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Inventory</h3></div>

       <div className='container-fluid m-0 text-center bg-white'>
       <img src={taxi} className='w-50 mx-auto '/>
       </div>
    
       <div className='bg-white mx-2 rounded-top box-shadow px-0 pt-0  py-0 position-relative top-0 animate__animated animate__fadeInDown'>
         <div className="row m-0 py-3">
          <div className="col-sm-6">
            <h4><b>Upload Books:</b></h4>
          </div>
          <div className="col-sm-5">
            <div className="form-group ">
            <form method='post' id='register_form' action='process/login' onSubmit={process} >
              <div className="d-flex flex-row align-items-start">
              <input type='file' name="book" id="book" className="form-control" required/>
            <ButtonInput value = "Add Book" active="btn-deactive mr-3" />
              </div>
            


              </form>
            </div>
            
          </div>
         </div>
         <hr/>
         <div className="row m-0 p-3">
          <h4><b>Uploaded Books</b></h4>
          <ul>
          {members.map((book) => <Book name={book.book} key={book.id} />)}
          </ul>
          {num_books? <b className='text-danger'>No Books uploaded yet</b> : ""} 
          </div>

        
         </div> 
         </>
    );
}


import React, { useEffect, useState } from 'react';

import './index.scss';
import Header  from '../../components/Header'; 
import { Outlet, Link, Navigate } from "react-router-dom";
import {Container} from 'react-bootstrap';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from '../Footer';
import Home  from '../Home';
import About  from '../About';
import Nopage from '../Nopage';
import Login from '../Login';
import Logout from '../Logout';
import Inventory from '../Inventory';
import Adduser from '../User';
import CreateSlip from '../Expendature/CreateSlip'
import Worker from '../Worker';
import Settings from '../Settings';
import AddWorker from '../Worker/Addworker';
import ReceiptAward from '../Receipt/award.js';
import ReceiptStand from '../Receipt/stand.js';
import ReceiptAttendance from '../Receipt/attendance.js';
import Edituser from '../User/edituser';
import EditDriver from '../Driver/EditDriver';
import EditMember from '../Members/EditMember';
import EditWorker from '../Worker/EditWorker';
import Colleague from '../Receipt/AddColleague';
import Register from '../Members/Register';
import ReceiptColleague from '../Receipt/colleague.js';
import Expendature from '../Expendature';
import Receipt from '../Receipt';
import ReceiptBurial from '../Receipt/burial.js';
import UserDelete from '../User/UserDelete';
import DriverDelete from '../Driver/DriverDelete';
import MemberDelete from '../Members/MemberDelete';
import BookDelete from '../Inventory/BookDelete';
import WorkerDelete from '../Worker/WorkerDelete';
import ParcelDelete from '../Driver/ParcelDelete';
import MSGDialogue from '../../components/Dialogue';
import {BaseAPIurl, getCookie,GlobalType,rootUrl,isDriver} from '../../App';
import AddAward from '../Receipt/addaward';
import ColleagueEdit from '../Receipt/EditColleague';
import StandEdit from '../Receipt/EditStand';
import DeleteColleague from '../Receipt/ColleagueDelete';
import ExpenseDelete from '../Expendature/ExpenseDelete';
import AddBurial from '../Receipt/AddBurial';
import BurialDelete from '../Receipt/BurialDelete';
import StandAward from '../Receipt/AddStand';
import StandDelete from '../Receipt/StandDelete';
import AddAttendance from '../Receipt/AddAttendance';
import EditMeeting from '../Receipt/EditMeeting';
import MeetingDelete from '../Receipt/MeetingDelete';
import { GlobalUser } from '../../App';
import UserRenew from '../User/UserRenew';
import Pause from '../User/Pause';
import Resume from '../User/Resume';
import ManageDriver from '../Driver';
import ManageVehicle from '../Vehicle';
import AddVehicle from '../Vehicle/add';
import DriverDashboard from '../Driver/Dashboard';
import AddExpense from '../Driver/AddExpense';
import CostDelete from '../Driver/CostDelete';
import DriverStats from '../Driver/DriverStats';
import Parcels from '../Driver/Parcels';
import AddParcel from '../Driver/AddParcel';
import EditParcel from '../Driver/EditParcel';
import ParcelDelivered from '../Driver/Delivered';
import Tracking from '../Tracking';



function MenuBar(props){    

    return(
<>
<div className="mx-2 mt-3 ">
<Link to={props.path} style={{textDecoration:'none'}}>
    <div onClick={() => props.action(props.id,props.path)} className={ props.active + ' menu-button button px-2 py-1 rounded text-center mr-2 ml-2'  }>
        <span className="position-relative float-left menu-icons ml-1 mr-2 "><i className={props.icon}></i></span>
        <span className='text-center menu-btn-text ' >{props.title}</span>
    </div>
    </Link>
</div>
</>
    );

}

function Powered(){
    
return(<>
<div className="mx-2 mt-3 text-secondary position-absolute w-100 bottom-0 text-center">
    <span className='text-center'> Powered By <a href='https://www.weball.co.za'>Weball Technologies</a></span>
</div>
</>);

}


function SideBar(){

    let login = true;

    var activated = (id)=>{
        let els =  document.getElementsByClassName("menu-button");

        for (let i=0; i<els.length-1;i++){
            els[i].classList.remove("btn-menu-active");
            els[i].classList.add("btn-menu-deactive");
        }
        els[id-1].classList.remove("btn-menu-deactive");
       els[id-1].classList.add("btn-menu-active");

    setTimeout(()=>{
    //eslint-disable-next-line no-restricted-globals
    if(screen.width<992){
        document.getElementsByClassName("side-panel")[0].classList.remove("expand-side");
        document.getElementsByClassName("side-main")[0].classList.remove("expand-side-main");
        document.getElementsByClassName("side-panel")[0].classList.add("collapse-side");
        document.getElementsByClassName("side-main")[0].classList.add("collapse-side-main");
    }

   },1000);
    
    // eslint-disable-next-line no-restricted-globals
   // location.href =rootUrl  + path;

    }

    if(GlobalType=="owner" || GlobalType=="Rank" ){
        
    var menulist = [
        {id: 1, title:"Dashboard", icon:"fa fa-home", active:"btn-menu-active", path:"/dashboard"},
        {id: 2, title:"Registration", icon:"fa fa-users", active:"btn-menu-deactive", path:"/register"},
        {id: 3, title:"Manage Drivers", icon:"fa fa-id-card", active:"btn-menu-deactive", path:"/managedriver"},
        {id: 4, title:"Manage Vehicle", icon:"fa fa-car", active:"btn-menu-deactive", path:"/vehicle"},
        {id: 5, title:"Receipts", icon:"fa fa-file-o", active:"btn-menu-deactive", path:"/receipts"},
        {id: 6, title:"Expenditure ", icon:"fa fa-money", active:"btn-menu-deactive", path:"/Expenditure"},
        {id: 7, title:"Workers", icon:"fa fa-car", active:"btn-menu-deactive", path:"/Worker"},
        {id: 8, title:"Inventory", icon:"fa fa-book", active:"btn-menu-deactive", path:"/Inventory"},
        {id: 9, title:"Manage User", icon:"fa fa-user", active:"btn-menu-deactive", path:"/adduser"},
        {id: 10, title:"Settings", icon:"fa fa-cogs", active:"btn-menu-deactive", path:"/settings"}
    ];


    }
    else if(GlobalType=="driver" || GlobalType==""){
          
    var menulist = [
        {id: 1, title:"Overview", icon:"fa fa-home", active:"btn-menu-active", path:"/driver/dashboard"},
        {id: 2, title:"Record Expenses ", icon:"fa fa-money", active:"btn-menu-deactive", path:"/driver/addexpense"},
        {id: 3, title:"Manage Parcels", icon:"fa fa-suitcase", active:"btn-menu-deactive", path:"/parcels"},
        {id: 4, title:"Settings", icon:"fa fa-cogs", active:"btn-menu-deactive", path:"/settings"}
    ];
    }
    else{
      

        var menulist = [
            {id: 1, title:"Dashboard", icon:"fa fa-home", active:"btn-menu-active", path:"/dashboard"},
            {id: 2, title:"Registration", icon:"fa fa-users", active:"btn-menu-deactive", path:"/register"},
            {id: 3, title:"Receipts", icon:"fa fa-file-o", active:"btn-menu-deactive", path:"/receipts"},
            {id: 4, title:"Expenditure ", icon:"fa fa-money", active:"btn-menu-deactive", path:"/Expenditure"},
            {id: 5, title:"Manage Drivers", icon:"fa fa-id-card", active:"btn-menu-deactive", path:"/managedriver"},
            {id: 6, title:"Workers", icon:"fa fa-car", active:"btn-menu-deactive", path:"/Worker"},
            {id: 7, title:"Inventory", icon:"fa fa-book", active:"btn-menu-deactive", path:"/Inventory"},
            {id: 8, title:"Settings", icon:"fa fa-cogs", active:"btn-menu-deactive", path:"/settings"}
        ];
    
    }
    var close = ()=>{

        // eslint-disable-next-line no-restricted-globals
        if(screen.width<992){
            
            document.getElementsByClassName("side-panel")[0].classList.remove("expand-side");
            document.getElementsByClassName("side-main")[0].classList.remove("expand-side-main");
        
            document.getElementsByClassName("side-panel")[0].classList.add("collapse-side");
            document.getElementsByClassName("side-main")[0].classList.add("collapse-side-main");

        }
     
    }
   
    if(login){
        let person = "Username"
        if(GlobalType=="Rank"){
            person = "Rank Admin";
        }
        if(GlobalType=="owner"){
            person = "Master Admin";
        }
        
        if(GlobalType=="driver"){
            person = "Driver Admin";
            //eslint-disable-next-line no-restricted-globals 
            // window.location.href= rootUrl + "/driver/dashboard";
        }

        return (<>
         <div className='user-avatar'><div className='user mx-1 p-auto'><i className='fa fa-user'></i></div>
          <span className='text-secondary'>{person}</span></div>

        <div className="side-panel p-0 expand-side p-hide transition-all-1s" >
                <div className='text-danger float-right d-hide close-side-panel mb-2 mr-2' onClick={close}><i className='fa fa-close text-danger'></i></div>
                <br />
                {menulist.map((button) => <MenuBar key={button.id}  id={button.id} action={activated} title={button.title} active={button.active}
                 icon ={button.icon}   path ={button.path}  />)}
                 <hr />
                 <br/>
                
                 <div className="logout-button mt-4">
                    <MenuBar title="Sign in"  id="9" active="btn-menu-deactive" action={activated} icon="fa fa-arrow-circle-o-right" path = "login" />
                 </div>
                 <div className="logout-button mt-1 ">
                    <MenuBar title="logout" active="btn-menu-active" icon="fa fa-arrow-circle-o-right" path = "logout" />
                 </div>
                
                

            </div>
        
        </>)
    }
    else{
        return(
            <>
            <Navigate to =  '/test'  />
            </>
        )
        
    }

}

export default function Layout(){

var auth = false;

 if(getCookie("softransuser") != null){
    var auth = true;
 }

    var close = ()=>{

       
        // eslint-disable-next-line no-restricted-globals
        if(screen.width<992){
            document.getElementsByClassName("side-panel")[0].classList.remove("expand-side");
            document.getElementsByClassName("side-panel")[0].classList.add("collapse-side");
        }
      
   
    };
    if(auth){
        
    return (
        <><BrowserRouter>
           
            <Header />
            <MSGDialogue msg = "You are already logged in" id = "msg2"  colors= "text-white bg-success" />
            <Container fluid className='layout p-0 bg-light'>
            <SideBar />
                <div className="container-fluid m-0 p-2 side-main expand-side-main " onClick={close}>
                    <Routes> 
                        {isDriver?<Route index element={<DriverDashboard />}/>:<Route index element={<Home />}/>}
                        <Route path="/" element={<Home />}/>
                        <Route path="/dashboard" element={<Home />}/>
                        <Route path="/home" element={<Home />}/>
                        <Route path="/about" element={<About />}/>
                        <Route path="/login" element={<Login />}/>
                        <Route path="/vehicle" element={<ManageVehicle />}/>
                        <Route path="/vehicle/addvehicle" element={<AddVehicle />}/>
                        <Route path="/logout" element={<Logout />}/>
                        <Route path="/inventory" element={<Inventory />}/>
                        <Route path="/adduser" element={<Adduser />}/>
                        <Route path="/pause" element={<Pause />}/>
                        <Route path="/resume" element={<Resume />}/>
                        <Route path="/adduser/edituser" element={<Edituser/>}/>
                        <Route path="/managedriver/editdriver" element={<EditDriver/>}/>
                        <Route path="/driver/dashboard" element={<DriverDashboard/>}/>
                        <Route path="/adduser/renewuser" element={<UserRenew/>}/>
                        <Route path="/register" element={<Register />}/>
                        <Route path="/Worker/addworker" element={<AddWorker />}/>
                        <Route path="/editmember" element={<EditMember />}/>
                        <Route path="/editworker" element={<EditWorker />}/>
                        <Route path="/driver/editparcel" element={<EditParcel />} />
                        <Route path="/settings" element={<Settings />}/>
                        <Route path="/Expenditure" element={<Expendature />}/>
                        <Route path="/driver/addexpense" element={<AddExpense />}/>
                        <Route path="/driver/addparcels" element={<AddParcel />}/>
                        <Route path="/parcels" element={<Parcels />}/>
                        <Route path="/driverstats" element={<DriverStats />}/>
                        <Route path="/driver/costdelete" element={<CostDelete />}/>
                        <Route path="/driver/deleteparcel" element={<ParcelDelete />}/>
                        <Route path="/driver/addparcel" element={<AddParcel />}/>
                        <Route path="/parcel/delivered" element={<ParcelDelivered />}/>
                        <Route path="/editexpenses" element={<CreateSlip />}/>
                        <Route path="/receipts/award" element={<ReceiptAward />}/>
                        <Route path="/receipts/stand/addstand" element={<StandAward />}/>
                        <Route path="/receipts/award/addaward" element={<AddAward />}/>
                        <Route path="/receipts/burial/addburial" element={<AddBurial />}/>
                        <Route path="/receipts/colleagues" element={<ReceiptColleague />}/>
                        <Route path="/receipts/attendance" element={<ReceiptAttendance />}/>
                        <Route path="/editmeeting" element={<EditMeeting />}/>
                        <Route path="/receipts/burial" element={<ReceiptBurial />}/>
                        <Route path="/receipts/stand" element={<ReceiptStand />}/>
                        <Route path="/worker" element={<Worker />}/> 
                        <Route path="/dashboard/register" element={<Register />}/>
                        <Route path="/receipts/colleagues/addcolleague" element={<Colleague />}/>
                        <Route path="/receipts/attendance/addattendance" element={<AddAttendance />}/>
                        <Route path="/editcolleague" element={<ColleagueEdit />}/>
                        <Route path="/editstand" element={<StandEdit />}/>
                        <Route path="/userdelete" element={<UserDelete />}/>
                        <Route path="/driverdelete" element={<DriverDelete />}/>
                        <Route path="/receipts" element={<Receipt />}/>
                        <Route path="/receipts/register" element={<Receipt />}/>
                        <Route path="/memberdelete" element={<MemberDelete />}/> 
                        <Route path="/workerdelete" element={<WorkerDelete />}/>
                        <Route path="/managedriver" element={<ManageDriver />}/>
                        <Route path="/deletecolleague" element={<DeleteColleague />}/>
                        <Route path="inventory/bookdelete" element={<BookDelete />}/>
                        <Route path="/expensedelete" element={<ExpenseDelete />}/>
                        <Route path="/deletestand" element={<StandDelete />}/>
                        <Route path="/deletemeeting" element={<MeetingDelete />}/>
                        <Route path="/receipts/burial/deleteburial" element={<BurialDelete />}/>
                        <Route path="*" element={<Nopage />}/>
                    </Routes>
                   
            </div>
            
</Container> 

        </BrowserRouter><Outlet /></>
        
    );

    }
    else{
        return (
        <><BrowserRouter>
           
            <Header />
            
            <Container fluid className='layout p-0'>
                <div className="container-fluid m-0 p-2" >
                    <Routes> 
                    <Route index element={<Login />}/>
                    <Route path="tracking" element={<Tracking />}/>
                    <Route path="*" element={<Nopage />}/>
                    </Routes>
                </div> 
            </Container> 
            <Footer />

        </BrowserRouter><Outlet /></> );
    }
}
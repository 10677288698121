import React,{useEffect,useState} from 'react';
import {Container,Row,Col,Table} from 'react-bootstrap';
import './index.scss';
import axios from 'axios'
import {APIurl,GlobalUser} from '../../App';
import TableUser from '../../components/TableUser'
import MSGDialogue from '../../components/Dialogue';


export default function Worker(){

  const [members,setMember] = useState([]);
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg16");
  
    
useEffect(() => {

  
    
//Get all members registered in the system
  axios.post(APIurl,{ 
      cmd: "getallworkers",
      username:GlobalUser
      
      } )
      .then((response) => {

      var res = response.data;
      

      if(!res["success"]){   
          setLogingmsg(res["data"]);
          setColors("Could not send request");
      }
      else{

          console.log(res["data"]);
          setMember(res["data"]);

       
          setLogging(true);
          document.getElementById(diaglogueID).style.display = "block"; 
         
      }
     
      })
      .catch((error) => {

      console.log(error);
      
      });


}, []);
    

      var table_headers = [
        
        {id:1,name:"ID"},,{id:3,name:"Name"},{id:4,name:"Surname"},
      {id:5,name:"Role"},
      {id:6,name:"Postal Address"}, {id:7,name:"Town"},{id:2,name:"Cellphone"},{id:8,name:"Manage"}
    ];
    var buttons = [{id:2,title:"Add Worker",path:"addworker",icon:"fa fa-plus mx-2"}]

    return (
        <>

         {logging? <MSGDialogue msg = {loginmsg} id = {diaglogueID}  colors= {colors} /> : ""} 

       <div className='mr-0 ml-0 p-2 mt-2 mb-0'><h3>Manage Workers</h3></div>

       <Container fluid className='d-flex flex-row mt-2 w-100 home-cards'></Container>
     <TableUser len={members.length} name="Workers" edit="/editworker" delete="/workerdelete" table_headers = {table_headers} members={members} buttons={buttons}/>

      
        </>
    );
}

